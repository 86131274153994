import angular from 'angular';
import 'ng-redux';

import '../golfleet-dashboard-block/golfleet-dashboard-block';
import '../golfleet-dashboard-chart/golfleet-dashboard-chart';
import '../golfleet-dashboard-error/golfleet-dashboard-error';
import '../golfleet-toolbar/golfleet-toolbar';
import '../golfleet-loader/golfleet-loader';
import '../golfleet-toast/golfleet-toast';
import '../golfleet-map-utilization/golfleet-map-utilization';
import { FilterCondition } from '../golfleet-filter/golfleet-filter';
import '../../directives/ng-tippy/ng-tippy';
import '../../helpers/is-iframe/is-iframe';

import template from './golfleet-dashboard.html';
import './golfleet-dashboard.scss';

class GolfleetDashboardController {
  violationChartId = 'chart-violation';

  travelChartId = 'chart-travel';

  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'urlApi',
      'filterServices',
      'commonServices',
      'dashboardServices',
      '_today',
      '_months',
      '$rootScope',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $http,
    urlApi,
    filterServices,
    commonServices,
    dashboardServices,
    _today,
    _months,
    $rootScope,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $http,
      urlApi,
      filterServices,
      commonServices,
      dashboardServices,
      _today,
      _months,
      $rootScope,
    });

    this.monthArray = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    this.__golfleetBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        lastState:
          behavior.state.routeList.length > 1
            ? behavior.state.routeList[behavior.state.routeList.length - 2]
            : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        /* Session Storage */
        refreshFilter: store.session.refreshFilter,
        modules: store.session.modules,
      }),
    )(this);

    this.configDataGraphicIndicatorsEvolution = { status: 0 };
    this.toastDashboard = {};

    this.lastUtilization = {
      timeInitial: '',
      timeFinal: '',
      plate: '',
      locationInitial: '',
      locationFinal: '',
      distance: '',
      violationAmount: 0,
    };
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    this.toastDashboard = {
      text: '',
    };

    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    if (
      !this.stateConfig.filterConfig ||
      this.stateConfig.filterConfig.length == 0 ||
      this.refreshFilter
    ) {
      this._getStateConfig();
    } else {
      setTimeout(() => this.requestDataset());
    }
    this.showRanking = this.modules.find(m => m === 'DRIVER_RANKING');
    this.$rootScope.$on('updateModules', (event, data) => {
      this.showRanking = data.find(m => m === 'DRIVER_RANKING');
    });
  }

  $onDestroy() {
    this.__golfleetBehavior();
  }
  /* */

  /* Public */
  requestDataset() {
    this._getDashboardData();
    this._getLastUtilization();
  }
  /* */

  /* Private */
  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _getStateConfig() {
    this.filterServices
      .getPowerFilters(this.stateConfig.screenName, 'Home')
      .then(getFiltersResult => {
        if (getFiltersResult) {
          const { drilldownMap } = getFiltersResult;
          const filterConfigList = getFiltersResult.filters;
          this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
            Object.clone({}, filterConfig),
          );
          if (
            this.lastState &&
            drilldownMap.length > 0 &&
            this.lastState.stateConfig.filterConfig
          ) {
            const lastFilterConfigList = this.lastState.stateConfig.filterConfig;
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const drilldown = drilldownMap.filter(
                // eslint-disable-next-line no-shadow
                drilldown => drilldown.toId == actualFilter.id,
              )[0];
              if (drilldown) {
                const previousFilter = lastFilterConfigList.filter(
                  filter => filter.id == drilldown.fromId,
                )[0];
                return {
                  ...actualFilter,
                  condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                };
              }
              return actualFilter;
            });
          }
          if (this.stateConfig.getDataFilters) {
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const fixedFilters = this.stateConfig.getDataFilters.filter(
                condition => condition.id == actualFilter.id,
              )[0];
              return fixedFilters
                ? {
                    ...actualFilter,
                    condition: new FilterCondition(
                      actualFilter.id,
                      actualFilter.field || actualFilter.filters[fixedFilters.activeView].field,
                      fixedFilters.default,
                    ),
                  }
                : actualFilter;
            });
          }
        }
        this.commonServices
          .getToolbarOptions(
            this.stateConfig.screenName,
            this.stateConfig.toolbarName,
            getToolbarResult => {
              const { addConfig, viewsConfig, exportConfig, importConfig, actionConfig } =
                getToolbarResult;
              Object.assign(this.stateConfig.toolbarConfig, {
                addConfig,
                viewsConfig,
                exportConfig,
                actionConfig,
                importConfig,
              });
              this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
            },
          )
          .then(() =>
            document
              .querySelector('golfleet-app')
              .querySelector('golfleet-filter-menu')
              .applyFilters(),
          );
      })
      .finally(() => {
        this.$ngRedux.dispatch({
          type: 'SIGNIN',
          data: {
            refreshFilter: false,
          },
        });

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _getDashboardData() {
    this.__clearData();

    this.dashboardServices
      .getDashboardConfig('Home')
      .then(dbResponse => {
        // Após carregar as configurações do Dashboard, carrega as informações dos Block's
        if (dbResponse.status && dbResponse.status === 500) {
          this.toastDashboard.text =
            'Ops! Ocorreu um erro ao carregar as configurações do seu Dashboard. Por favor, atualize a página.';
          this.$.querySelector('#toast-obj').toggle(true);
          return;
        }

        /* Status
         * 0: show loader
         * 1: ok, success
         * 2: not ok, error
         */
        this._getViolations(dbResponse.blocks.filter(item => item.type == 'violationsChart')[0]);
        this._getTravels(dbResponse.blocks.filter(item => item.type == 'travelsChart')[0]);
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _getLastUtilization() {
    const loaderComponent = this.$.querySelector('.dashboard-card golfleet-loader');

    loaderComponent.toggle(true);

    this.$http({
      url: `${this.urlApi}/Utilizations/GetPositionsByUtilization`,
      method: 'POST',
      data: {
        request: {
          onlyLastUtilization: true,
        },
      },
    })
      .then(async response => {
        const dataset = response.data.data.positions;
        const { distanceTotal } = response.data.data;
        if (dataset && dataset.length > 0) {
          const [
            {
              placa,
              endereco: enderecoInicial,
              dataHora: dataHoraInicial,
              veiculoPossuiRotaPrecisa: serviceMatchRouteIsActive,
            },
          ] = dataset;
          const [{ endereco: enderecoFinal, dataHora: dataHoraFinal }] = dataset.slice(-1);

          this.lastUtilization.timeInitial = dataHoraInicial;
          this.lastUtilization.timeFinal = dataHoraFinal;
          this.lastUtilization.plate = placa;
          this.lastUtilization.locationInitial = enderecoInicial;
          this.lastUtilization.locationFinal = enderecoFinal;
          this.lastUtilization.distance = distanceTotal.toFixed(2);
          this.lastUtilization.violationAmount = dataset.map(item => item.infracoes)?.flat().length;

          const mapComponent = this.$.querySelector('golfleet-map-utilization');

          if (serviceMatchRouteIsActive) {
            const gpxTemplate = (arr = []) => `<?xml version="1.0"?>
            <gpx version="1.0"
              xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
              xmlns="http://www.topografix.com/GPX/1/0"
              xsi:schemaLocation="http://www.topografix.com/GPX/1/0 http://www.topografix.com/GPX/1/0/gpx.xsd"
            >
              <trk>
                <trkseg>
                  ${arr
                    .map(
                      item =>
                        `<trkpt lat="${item.latitude.replace(
                          ',',
                          '.',
                        )}" lon="${item.longitude.replace(',', '.')}"/>`,
                    )
                    .join('\n        ')}
                </trkseg>
              </trk>
            </gpx>
          `;

            const request = arr =>
              this.$http({
                url: 'https://rme.cit.api.here.com/2/matchroute.json?routemode=car&app_id=Iajt61WMPoEvbbMdvMH8&app_code=Z7ncDhO8GxIIHGGDmzCkWQ',
                method: 'POST',
                headers: {
                  'Content-Type': 'text/plain',
                  Accept: '*/*',
                },
                data: gpxTemplate(arr),
              }).then(result => {
                const markers = result.data.TracePoints.map((tracePoint, index, tracePoints) => ({
                  ...dataset[index],
                  latitude:
                    tracePoint.confidenceValue > 0
                      ? tracePoint.latMatched
                      : dataset[index].latitude,
                  longitude:
                    tracePoint.confidenceValue > 0
                      ? tracePoint.lonMatched
                      : dataset[index].longitude,
                  first: index === 0,
                  last: index === tracePoints.length - 1,
                  markerIcon: 'RouteIcon',
                }));

                const linestrings = [
                  {
                    geoJson: {
                      type: 'LineString',
                      coordinates: result.data.RouteLinks.reduce(
                        (acc, routeLink) => [...acc, ...routeLink.shape.split(' ')],
                        [],
                      ).reduce((acc, routeLink, index, routeLinks) => {
                        if (index % 2 != 0) {
                          acc.push([routeLink, routeLinks[index - 1]]);
                        }
                        return acc;
                      }, []),
                    },
                  },
                ];

                return {
                  markers,
                  linestrings,
                };
              });

            const { markers, linestrings } = await request(dataset);

            mapComponent.renderDataset({
              dataset: markers,
              layerName: 'routeMarkers',
              type: 'MarkerFeatureGroup',
              useCluster: false,
              clusterColor: '#2196f3',
            });
            mapComponent
              .renderDataset({
                dataset: linestrings,
                layerName: 'routeLinestrings',
                type: 'LinestringFeatureGroup',
              })
              .then(() => {
                mapComponent.resizeMap();
                mapComponent.fitLayers(['routeMarkers']);
              });
          } else {
            const { markers, linestrings } = dataset.reduce(
              (acc, ele, index, arr) => {
                if (index == 0)
                  return Object.assign(acc, {
                    markers: acc.markers.concat({
                      ...ele,
                      first: true,
                      last: false,
                      markerIcon: 'RouteIcon',
                    }),
                  });
                return Object.assign(acc, {
                  markers: acc.markers.concat({
                    ...ele,
                    first: false,
                    last: index == arr.length - 1,
                    markerIcon: 'RouteIcon',
                  }),
                  linestrings: acc.linestrings.concat({
                    geoJson: {
                      type: 'LineString',
                      coordinates: [
                        [
                          this._formatLatLng(arr[index - 1].longitude),
                          this._formatLatLng(arr[index - 1].latitude),
                        ],
                        [this._formatLatLng(ele.longitude), this._formatLatLng(ele.latitude)],
                      ],
                      properties: { dataHora: ele.dataHora },
                    },
                  }),
                });
              },
              { markers: [], linestrings: [] },
            );

            mapComponent.renderDataset({
              dataset: markers,
              layerName: 'routeMarkers',
              type: 'MarkerFeatureGroup',
              useCluster: false,
              clusterColor: '#2196f3',
            });
            mapComponent
              .renderDataset({
                dataset: linestrings,
                layerName: 'routeLinestrings',
                type: 'LinestringFeatureGroup',
              })
              .then(() => {
                mapComponent.resizeMap();
                mapComponent.fitLayers(['routeLinestrings']);
              });
          }
        }
      })
      .finally(() => {
        loaderComponent.toggle(false);
      });
  }

  _getViolations(configs) {
    this.$http({
      url: `${this.urlApi}/Violation/GetDashboardViolations`,
      method: 'POST',
    }).then(
      success => {
        if (success.status && success.status != 200) {
          this.violationsConfig.status = 2;
          return;
        }
        this.violationsConfig.value = {
          id: this.violationChartId,
          middleText: this._getViolationMiddleText(
            success.data.data.result.average100Km,
            success.data.data.result.total,
          ),
          data: success.data.data.result.items,
          colors: configs.colors,
          month: this.monthArray[new Date(success.data.data.result.date).getMonth()],
          year: new Date(success.data.data.result.date).getFullYear(),
        };
        this.violationsConfig.status = 1;
      },
      () => {
        this.violationsConfig.status = 2;
      },
    );
  }

  _getTravels(configs) {
    this.$http({
      url: `${this.urlApi}/Travel/GetDashboardTravels`,
      method: 'POST',
    }).then(
      success => {
        if (success.status && success.status != 200) {
          this.travelsConfig.status = 2;
          return;
        }
        this.travelsConfig.value = {
          id: this.travelChartId,
          middleText: this._getTravelMiddleText(success.data.data.result.total),
          data: success.data.data.result.items,
          colors: configs.colors,
          month: this.monthArray[new Date(success.data.data.result.date).getMonth()],
          year: new Date(success.data.data.result.date).getFullYear(),
        };
        this.travelsConfig.status = 1;
      },
      () => {
        this.travelsConfig.status = 2;
      },
    );
  }

  _goToLastUtilization() {
    this.$state.go(
      'lastUtilizationReport',
      {
        // Addon Services
        routeId: 'mainLastUtilizationReport',
        serviceName: 'Última utilização',
        serviceIcon: '',
      },
      { reload: true },
    );
  }

  _goToViolations() {
    this.$state.go('violationReport', { reload: true });
  }

  _goToTravels() {
    this.$state.go('travelReport', { reload: true });
  }

  _openExternalUrl(url) {
    window.open(url, '_blank');
  }

  _getViolationMiddleText(average, total) {
    if (total > 0) {
      return `<div style="font-size: 55px; text-align: center;">${total}</div>
        <div style="border: 1px solid rgb(102 102 102); margin: 8px 0"></div>
        <div style="font-size: 30px; text-align: center;">
          ${average}
            <div style="font-size: 16px;">a cada 100 km</div>
        </div>`;
    }
    return `<div style="font-size: 35px; text-align: center;">
    0
    <div style="font-size: 16px;">Infrações</div>
    </div>`;
  }

  _getTravelMiddleText(total) {
    return `<div style="font-size: 35px; text-align: center;">
        ${total}
        <div style="font-size: 16px;">km rodados</div>
    </div>`;
  }

  /* */

  /* Protected */
  __clearData() {
    this.configDataGraphicIndicatorsEvolution = { status: 0 };
    this.violationsConfig = { status: 0 };
    this.travelsConfig = { status: 0 };
    this.rankingConfig = { status: 0 };
  }
  /* */
}

class GolfleetDashboard {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetDashboardController;
  }
}

angular
  .module('golfleet-dashboard', [
    'ngRedux',
    'ng-tippy',
    'golfleet-toolbar',
    'golfleet-dashboard-block',
    'golfleet-dashboard-chart',
    'golfleet-loader',
    'golfleet-dashboard-error',
    'golfleet-toast',
    'golfleet-map-utilization',
  ])
  .component('golfleetDashboard', new GolfleetDashboard());

export { GolfleetDashboardController };
