import Auth from 'oidc-client/index.js';
import { app } from '../app.module';

import dashboardConfigs from '../configs/app/dashboard.config';
import filterConfigs from '../configs/app/filter.config';
import toolbarConfigs from '../configs/app/toolbar.config';
import gridConfigs from '../configs/app/grid.config';
import menuConfigs from '../configs/app/menu.config';
import '../helpers/object-clone/object-clone';

/* CommonServices */
class CommonServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', 'deviceDetector', 'urlApi', 'tipoApp', '_authConfig'];
  }

  constructor($ngRedux, $http, deviceDetector, urlApi, tipoApp, _authConfig) {
    Object.assign(this, { $http, deviceDetector, urlApi, tipoApp, _authConfig });

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  resetPassword() {
    return this.$http({
      url: `${this._authConfig.authority}Account/ResetPassword`,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      data: `email=${encodeURIComponent(this.session.userEmail)}&clientId=${encodeURIComponent(
        this._authConfig.client_id,
      )}`,
    }).then(
      success => success,
      error => error,
    );
  }

  platformLog() {
    return this.$http({
      url: `${this.urlApi}/Log/CreateLogLogin`,
      method: 'POST',
      data: {
        tipoApp: this.tipoApp,
        browser: this.deviceDetector.browser,
        browserVersion: this.deviceDetector.browser_version,
      },
    }).then(
      success => success,
      error => error,
    );
  }

  getToolbarOptions(screen, name, callback) {
    return new Promise((resolve, reject) => {
      let configName = `ToolBar_${screen}`;
      configName = `${configName}_${name}`;
      const config = toolbarConfigs[configName];

      if (config) {
        if (typeof callback === 'function') {
          resolve(callback(Object.clone({}, config)));
        } else {
          resolve(Object.clone({}, config));
        }
      } else {
        reject(new Error('ToolBar > getToolbarOptions Error'));
      }
    });
  }

  getGridHeaders({ screenName, gridName }) {
    const getGridConfiguration = options => {
      const configName = `Grid_${options.id}_${options.gridName}`;
      return gridConfigs[configName];
    };

    return new Promise((resolve, reject) => {
      const gridConfiguration = getGridConfiguration({
        id: screenName,
        gridName,
        screenName,
      });

      if (gridConfiguration) {
        resolve(Object.clone({}, gridConfiguration));
      } else {
        reject(new Error('Grid > getGridHeaders Error'));
      }
    });
  }

  getMenuOptions(callback) {
    return new Promise((resolve, reject) => {
      const configName = 'Menu_Configuration';
      const config = menuConfigs[configName];

      if (config) {
        if (typeof callback === 'function') {
          resolve(callback(Object.clone({}, config)));
        } else {
          resolve(Object.clone([], config));
        }
      } else {
        reject(new Error('Menu > getMenuOptions Error'));
      }
    });
  }
}
app.factory('commonServices', CommonServices);
/* */

/* FilterServices */
class FilterServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', 'urlApi'];
  }

  constructor($ngRedux, $http, urlApi) {
    Object.assign(this, { $ngRedux, $http, urlApi });

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__golfleetStore();
  }

  getPowerFilters(screenName, gridName) {
    return new Promise((resolve, reject) => {
      const configName = `Filter_${screenName}_${gridName}`;
      const config = filterConfigs[configName];

      if (config) {
        const filters = config.filters.reduce((acc, item) => acc.concat(item), []);
        resolve(Object.clone({}, { ...config, filters }));
      } else {
        reject(new Error('Filter > getPowerFilters Error'));
      }
    });
  }

  getFilterOptions(data, requestApi) {
    return this.$http({
      url: `${this.urlApi}/s${requestApi}`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Filter > GetFilterResult Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }
}
app.service('filterServices', FilterServices);
/* */

/* DashboardServices */
class DashboardServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi'];
  }

  constructor($ngRedux, $http, $timeout, urlApi) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi });

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__golfleetStore();
  }

  getDashboardConfig(screen) {
    return new Promise((resolve, reject) => {
      const configName = `Dashboard_${screen}`;
      const config = dashboardConfigs[configName];

      if (config) {
        resolve(Object.clone({}, config));
      } else {
        reject(new Error('Dashboard > getDashboardConfig Error'));
      }
    });
  }

  getGraphic(data) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetGraphic`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetGraphic Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  getIndicatorsEvolution(data) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetIndicatorsEvolution`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetIndicatorsEvolution Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }
}
app.service('dashboardServices', DashboardServices);
/* */

/* ReportServices */
class ReportServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi'];
  }

  constructor($ngRedux, $http, $timeout, urlApi) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi });

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__golfleetStore();
  }

  getGridMaxPage(gridTotal, pageSize) {
    const pagesBySize = gridTotal / pageSize;
    const intPagesBySize = parseInt(pagesBySize, 10);
    return pagesBySize > intPagesBySize ? intPagesBySize + 1 : intPagesBySize;
  }

  genericRequest(method, payload) {
    if (method[0] === '/') {
      method = method.slice(1, method.length);
    }

    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }
}
app.service('reportServices', ReportServices);
/* */

/* AuthServices */
class AuthServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '_authConfig'];
  }

  constructor($ngRedux, _authConfig) {
    Object.assign(this, { $ngRedux, _authConfig });

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);

    this.userManager = this.getClientSettings();
  }

  async getUser() {
    try {
      return await this.userManager.getUser();
    } catch {
      return null;
    }
  }

  getClientSettings() {
    return new Auth.UserManager(this._authConfig);
  }

  setLogin() {
    this.userManager.signinRedirect();
  }

  setLogout() {
    this.userManager.signoutRedirect();
    this.$ngRedux.dispatch({ type: 'SIGNOUT' });
    this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
  }
}
app.service('authServices', AuthServices);
/* */

/* FunctionalityServices */
class FunctionalityServices {
  // Atualizar as funcionalidades do usuário a cada 10 minutos
  intervalRealTimeDefault = 600000;

  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi', '$rootScope'];
  }

  constructor($ngRedux, $http, $timeout, urlApi, $rootScope) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi, $rootScope });

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__golfleetStore();
  }

  genericRequest(method, payload) {
    if (method[0] === '/') {
      method = method.slice(1, method.length);
    }

    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  _getFunctionalitiesRealTime() {
    this._getFunctionalities();
    setInterval(() => {
      if (this.session.token) {
        this._getFunctionalities();
      }
    }, this.intervalRealTimeDefault);
  }

  _getFunctionalities() {
    this.genericRequest('Functionality/GetFunctionalities').then(response => {
      if (response.status === 200 && response.data) {
        const { data } = response.data;
        if (this.session.modules && data !== this.session.modules) {
          this.$ngRedux.dispatch({
            type: 'UPDATE_MODULES',
            data: {
              modules: data || [],
            },
          });
          this.$rootScope.$emit('updateModules', data || []);
        }
      }
    });
  }
}
app.service('functionalityServices', FunctionalityServices);
/* */
