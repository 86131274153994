/* eslint-disable no-param-reassign */

import angular from 'angular';
import '@uirouter/angularjs';
import 'angular-animate';
import 'angular-aria';
import 'angular-messages';
import 'angular-resource';
import 'angular-sanitize';
import 'ng-device-detector';
import 'ng-redux';
import 're-tree';
import 'ua-device-detector';

import './components/golfleet-popup/golfleet-popup';
import './components/golfleet-loader/golfleet-loader';
import './components/golfleet-user-menu/golfleet-user-menu';
import './components/golfleet-dashboard/golfleet-dashboard';
import './components/golfleet-filter-menu/golfleet-filter-menu';
import './components/golfleet-history-menu/golfleet-history-menu';
import './components/golfleet-navigation-menu/golfleet-navigation-menu';
import './components/golfleet-report-utilization/golfleet-report-utilization';
import './components/golfleet-popup-privacy-policy/golfleet-popup-privacy-policy';
import './components/golfleet-card-ranking/golfleet-card-ranking';
import './components/golfleet-chart-report/golfleet-chart-report';
import './components/golfleet-report-ranking/golfleet-report-ranking';

export const app = angular
  .module('app', [
    /* Dependencies */
    'ng.deviceDetector',
    'ngAnimate',
    'ngAria',
    'ngMessages',
    'ngRedux',
    'ngResource',
    'ngSanitize',
    'ui.router',
    /* */
    /* Components */
    'golfleet-popup',
    'golfleet-loader',
    'golfleet-user-menu',
    'golfleet-dashboard',
    'golfleet-filter-menu',
    'golfleet-history-menu',
    'golfleet-navigation-menu',
    'golfleet-report-utilization',
    'golfleet-popup-privacy-policy',
    'golfleet-card-ranking',
    'golfleet-chart-report',
    'golfleet-report-ranking',
    /* */
  ])
  .config([
    '$httpProvider',
    $httpProvider => {
      $httpProvider.interceptors.push('authInterceptorService');
      $httpProvider.defaults.headers.common.Accept =
        'application/json, text/javascript, text/html, image/svg+xml, application/x-www-form-urlencoded';
      $httpProvider.defaults.headers.common['Content-Type'] =
        'application/json; text/html; image/svg+xml; charset=utf-8';
      $httpProvider.defaults.useXDomain = true;
      delete $httpProvider.defaults.headers.common['X-Requested-With'];
    },
  ]);
