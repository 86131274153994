import angular from 'angular';

import { GolfleetFilterController, GolfleetFilter } from '../golfleet-filter/golfleet-filter';

import template from './golfleet-filter-switch.html';
import './golfleet-filter-switch.scss';

class GolfleetFilterSwitchController extends GolfleetFilterController {
  static get $inject() {
    return ['$element', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $scope, $http, urlApi) {
    super($element, $scope, $http, urlApi);

    this._value = false;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setFilterValue: this.setFilterValue.bind(this),
    });

    this.description = this.description || 'Carregando...';

    this._initializeFilter();
  }
  /* */

  /* Public */
  toggle() {}
  /* */

  /* Private */
  _initializeFilter() {
    if (this.condition) this._value = this.condition.value[0];
    else if (this.default) this._value = this.default[0];
    this.setFilterValue(this._value);
    this._formatDescription(this.singular);
  }

  _formatDescription(description) {
    this.description = this._formatDescriptionWords(
      this._applyFormatter(`${description}: ${this._value ? 'sim' : 'não'}`),
    );
  }

  _toggleValue() {
    this._value = !this._value;
    this.setFilterValue(this._value);
  }
  /* */
}

class GolfleetFilterSwitch extends GolfleetFilter {
  constructor() {
    super();
    this.controller = GolfleetFilterSwitchController;
    this.template = template;
  }
}

angular
  .module('golfleet-filter-switch', [])
  .component('golfleetFilterSwitch', new GolfleetFilterSwitch());
