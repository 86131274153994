import angular from 'angular';

import template from './golfleet-dashboard-block.html';
import './golfleet-dashboard-block.scss';

/**
 *  Power Block
 *
 *  Descrição: Define um elemento de bloco, normalmente utilizado em Dashboards
 *
 *  Parâmetros via Tag:
 *		- type: String (Define o tipo do Block a ser exibido. Default, Chart)
 *	  - size: Object (Define uma largura e altura específica para o Block. Ex: { "width": "20px"; "height": "20px" })
 *
 *  Parâmetros via HTML interno:
 *		- <block-slot></block-slot>	(Elemento a ser exibido internamente no componente, ex: gráficos, tabelas, etc)
 *
 * */
class GolfleetDashboardBlockController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
  }
}

class GolfleetDashboardBlock {
  constructor() {
    this.template = template;
    this.bindings = {
      type: '@?',
      size: '=?',
    };
    this.transclude = {
      content: '?blockSlot',
    };
    this.controller = GolfleetDashboardBlockController;
  }
}

angular
  .module('golfleet-dashboard-block', [])
  .component('golfleetDashboardBlock', new GolfleetDashboardBlock());
