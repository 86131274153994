import angular from 'angular';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import Regression from 'highcharts-regression';

import '../golfleet-dropdown/golfleet-dropdown';
import '../../helpers/format-number/format-number';

import template from './golfleet-dashboard-chart.html';
import './golfleet-dashboard-chart.scss';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);
Regression(Highcharts);
class GolfleetDashboardChartController {
  static get $inject() {
    return ['$element', '$timeout', '_today', '_months', 'dashboardServices'];
  }

  constructor($element, $timeout, _today, _months, dashboardServices) {
    Object.assign(this, {
      $: $element[0],
      $timeout,
      _today,
      _months,
      dashboardServices,
    });

    this.chart = null;
    this.middleText = null;
    this.chartId = '';
    this.showLegend = false;
  }

  /* Lifecycle */
  $onInit() {
    setTimeout(() => {
      this._createChart();
    }, 100);
  }
  /* */

  /* Private */
  _getId() {
    return this.chartData.id;
  }

  _toogleLegend() {
    if (this.chartData.data.filter(d => d.count > 0).length > 0) {
      this.showLegend = !this.showLegend;
    }
  }

  _createChart() {
    const series = [];
    if (this.chartData.data.filter(d => d.count > 0).length > 0) {
      this.chartData.data.forEach(element => {
        series.push([element.description, element.count]);
      });
    } else {
      series.push(['Vazio', 1]);
    }
    this.chart = Highcharts.chart(this.chartData.id, {
      title: {
        text: null,
      },
      subtitle: {
        useHTML: true,
        text: this.chartData.middleText,
        floating: true,
        verticalAlign: 'middle',
        style: {
          fontFamily: 'Ubuntu',
        },
      },

      legend: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      exporting: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          colorByPoint: true,
          type: 'pie',
          size: '100%',
          innerSize: '80%',
          dataLabels: {
            enabled: false,
            crop: false,
            distance: '-10%',
          },
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      colors: this._getColors(),
      series: [
        {
          type: 'pie',
          name: null,
          data: series,
        },
      ],
    });
  }

  _getColors() {
    if (this.chartData.data.filter(d => d.count > 0).length > 0) {
      return this.chartData.colors;
    }
    return ['#c3c3c3'];
  }

  _getBackgroundColor(index) {
    return {
      'background-color': this.chartData.colors[index],
    };
  }

  /* */
}

class GolfleetDashboardChart {
  constructor() {
    this.template = template;
    this.bindings = {
      chartData: '=?',
    };
    this.controller = GolfleetDashboardChartController;
  }
}

angular
  .module('golfleet-dashboard-chart', [])
  .component('golfleetDashboardChart', new GolfleetDashboardChart());
