import { app } from './app.module';
import template from './golfleet-app.component.html';
import './golfleet-app.component.scss';

class GolfleetAppController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      '$location',
      'deviceDetector',
      'urlApi',
      'tipoApp',
      'version',
      'playStore',
      'isProgressiveWebApp',
      'isTrustedWebActivity',
      'commonServices',
      'authServices',
      'functionalityServices',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $http,
    $location,
    deviceDetector,
    urlApi,
    tipoApp,
    version,
    playStore,
    isProgressiveWebApp,
    isTrustedWebActivity,
    commonServices,
    authServices,
    functionalityServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $http,
      $location,
      deviceDetector,
      urlApi,
      tipoApp,
      version,
      playStore,
      isProgressiveWebApp,
      isTrustedWebActivity,
      commonServices,
      authServices,
      functionalityServices,
    });

    window.dataLayer.push({ gfop_system: deviceDetector.os });
    window.dataLayer.push({ gfapp_version: this.version });

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        /* Session Storage */
        sessionData: store.session,
        token: store.session.token,
        userEmail: store.session.userEmail,
        hasUpdate: store.session.hasUpdate,
        keepSession: store.session.keepSession,
        hasRequiredUpdate: store.session.hasRequiredUpdate,
        privacyPolicyStatus: store.session.privacyPolicyStatus,
        /* State Storage */
        currentState: store.state.routeList[store.state.routeList.length - 1],
      }),
    )(this);

    if (isTrustedWebActivity) {
      this.$ngRedux.dispatch({
        type: 'UPDATE_USAGE_INFORMATION',
        data: {
          applicationType: 'TWA',
          operationalSystem: deviceDetector.os,
        },
      });
    } else if (isProgressiveWebApp) {
      this.$ngRedux.dispatch({
        type: 'UPDATE_USAGE_INFORMATION',
        data: {
          applicationType: 'PWA',
          operationalSystem: deviceDetector.os,
        },
      });
    } else {
      this.$ngRedux.dispatch({
        type: 'UPDATE_USAGE_INFORMATION',
        data: {
          applicationType: 'Browser',
          operationalSystem: deviceDetector.os,
        },
      });
    }

    if (this.userEmail && process.env.NODE_ENV === 'production') {
      const params = {
        extra: {
          token: this.token,
          keepSession: this.keepSession,
          tipoApp: this.tipoApp,
        },
        user: {
          email: this.userEmail,
        },
      };

      window.dispatchEvent(
        new CustomEvent('updateSentryScope', { detail: params, bubbles: true, composed: true }),
      );
    }

    this.navigator = window.navigator;
    this.toggleLoaderEvent = this._onToggleLoader.bind(this);
    this.toggleAppInfoEvent = this._onToggleAppInfo.bind(this);
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      /* Public */
      reload: this.reload.bind(this),
    });

    this.toggleLoaderEvent = this.$.addEventListener('toggleLoader', this.toggleLoaderEvent);
    this.toggleAppInfoEvent = this.$.addEventListener('toggleAppInfo', this.toggleAppInfoEvent);

    this.$scope.$watch(() => this.token, this.__tokenChanged.bind(this));
    this.$scope.$watch(() => this.hasRequiredUpdate, this.__hasRequiredUpdateChanged.bind(this));

    this.$scope.$watch(
      () => this.privacyPolicyStatus?.needUserAccept,
      this.__privacyPolicyStatusChanged.bind(this),
    );
    this.$scope.$watch(
      () => (this.currentState ? this.currentState.routeLink : null),
      this.__routeChanged.bind(this),
    );
    this.$scope.$watch(
      () =>
        this.currentState && this.currentState.stateConfig
          ? this.currentState.stateConfig.filterConfig
          : null,
      // this.__filterConfigChanged.bind(this),
      true,
    );

    this._checkLogin();

    window.addEventListener(
      'offline',
      () => {
        document.querySelector('golfleet-toast#app-status').toggle(true);
      },
      false,
    );

    window.addEventListener(
      'online',
      () => {
        document.querySelector('golfleet-toast#app-status').toggle(false);
      },
      false,
    );

    this.functionalityServices._getFunctionalitiesRealTime();
  }

  $onDestroy() {
    this.__golfleetStore();
    this.$.removeEventListener('toggleLoader', this.toggleLoaderEvent);
  }
  /* */

  /* Public */
  reload() {
    this.$ngRedux.dispatch({ type: 'APP_UPDATED' });

    window.location.reload();
  }

  toggleFilterMenu() {
    this.$.querySelector('golfleet-filter-menu').toggle();
  }

  toggleNavigationMenu() {
    this.$.querySelector('golfleet-navigation-menu').toggle();
  }
  /* */

  /* Private */
  _onToggleLoader(evt) {
    this.$.querySelector('golfleet-loader').toggle(evt.detail.showLoader);
  }

  _onToggleAppInfo() {
    if (this.$.querySelector('#popup-app-info').toggle)
      this.$.querySelector('#popup-app-info').toggle();
  }

  _openNavigationMenu() {
    document.querySelector('golfleet-navigation-menu').toggle();
  }

  _checkLogin() {
    if (this.$.querySelector('golfleet-loader').toggle)
      this.$.querySelector('golfleet-loader').toggle(false);

    this.authServices.getUser().then(user => {
      if (!user) {
        this.authServices.setLogin();
      } else {
        this.$.removeAttribute('unresolved');
        if (document.querySelector('#splash-container')) {
          document.querySelector('#splash-container').remove();
        }
        this.$.setAttribute('authorized', '');
        this.$ngRedux.dispatch({
          type: 'SIGNIN',
          data: {
            userName: user.profile.userName,
            userEmail: user.profile.userEmail,
            companyName: user.profile.companyName,
            token: user.access_token,
          },
        });
        if (!window.location.hash) {
          this.$state.go('dashboard');
        }
        this.commonServices.platformLog();
      }
    });
  }

  _updateThemeColors() {
    const hexToRgb = hex => {
      const curry =
        fn =>
        (...args) =>
          fn.bind(null, ...args);
      const compose =
        (...fns) =>
        x =>
          fns.reduceRight((v, f) => f(v), x);
      const exec = curry((regex, str) => regex.exec(str));
      const replace = curry((param, fn, str) => str.replace(param, fn));
      const sumMrgb = (m, r, g, b) => r + r + g + g + b + b;
      const rgbArrToRgb = rgbArr =>
        rgbArr
          ? { r: parseInt(rgbArr[1], 16), g: parseInt(rgbArr[2], 16), b: parseInt(rgbArr[3], 16) }
          : { r: 0, g: 0, b: 0 };
      const execRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
      const replaceRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      return compose(rgbArrToRgb, exec(execRegex), replace(replaceRegex, sumMrgb))(hex);
    };

    /* Common Colors */
    document.documentElement.style.setProperty(
      '--primary-color',
      this.themeColors.primaryColor || '#B400FF',
    );
    document.documentElement.style.setProperty(
      '--primary-text-color',
      this.themeColors.primaryTextColor || '#FFF',
    );
    /* Header Colors */
    document.documentElement.style.setProperty(
      '--header-color',
      this.themeColors.headerColor || '#FFF',
    );
    document.documentElement.style.setProperty(
      '--header-logo-color',
      this.themeColors.headerLogoColor || this.themeColors.primaryColor || '#B400FF',
    );
    document.documentElement.style.setProperty(
      '--header-text-color',
      this.themeColors.headerTextColor || '#949494',
    );
    document.documentElement.style.setProperty(
      '--header-badge-color',
      this.themeColors.headerBadgeColor || '#B400FF',
    );
    /* Navigation Colors */
    document.documentElement.style.setProperty(
      '--navigation-color',
      this.themeColors.navigationColor || '#9300D0',
    );
    document.documentElement.style.setProperty(
      '--navigation-dark-color',
      this.themeColors.navigationDarkColor || '#9300D0',
    );
    document.documentElement.style.setProperty(
      '--navigation-light-color',
      this.themeColors.navigationLightColor || '#B400FF',
    );
    document.documentElement.style.setProperty(
      '--navigation-selected-color',
      this.themeColors.navigationSelectedColor || 'rgba(0, 0, 0, 0.1)',
    );
    document.documentElement.style.setProperty(
      '--navigation-text-color',
      this.themeColors.navigationTextColor || 'rgba(255, 255, 255, 0.66)',
    );
    document.documentElement.style.setProperty(
      '--navigation-text-dark-color',
      this.themeColors.navigationTextDarkColor || 'rgba(0, 0, 0, 0.33)',
    );
    document.documentElement.style.setProperty(
      '--navigation-text-light-color',
      this.themeColors.navigationTextLightColor || '#FFF',
    );
    document.documentElement.style.setProperty(
      '--navigation-text-selected-color',
      this.themeColors.navigationTextSelectedColor || '#FFF',
    );
    /* Admin Navigation Colors */
    document.documentElement.style.setProperty(
      '--navigation-admin-color',
      this.themeColors.navigationAdminColor || '#282828',
    );
    document.documentElement.style.setProperty(
      '--navigation-admin-dark-color',
      this.themeColors.navigationAdminDarkColor || '#202020',
    );
    document.documentElement.style.setProperty(
      '--navigation-admin-light-color',
      this.themeColors.navigationAdminLightColor || '#323232',
    );
    document.documentElement.style.setProperty(
      '--navigation-admin-text-color',
      this.themeColors.navigationAdminTextColor || 'rgba(255, 255, 255, 0.66)',
    );
    document.documentElement.style.setProperty(
      '--navigation-admin-text-light-color',
      this.themeColors.navigationAdminTextLightColor || '#FFF',
    );
    /* Calendar */
    const primaryRgb = hexToRgb(this.themeColors.primaryColor || '#B400FF');
    document.documentElement.style.setProperty(
      '--primary-faded-color',
      `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 0.1)`,
    );
  }
  /* */

  /* Observers */
  // __filterConfigChanged() {
  //   if (
  //     !this.currentState ||
  //     !this.currentState.stateConfig ||
  //     !this.currentState.stateConfig.filterConfig ||
  //     this.currentState.stateConfig.filterConfig.length == 0 ||
  //     this.currentState.stateConfig.filterConfig.filter(item => item.visible).length == 0
  //   ) {
  //     this.$.querySelector('#navigation-filter-toggle').setAttribute('disabled', '');
  //   } else this.$.querySelector('#navigation-filter-toggle').removeAttribute('disabled');
  // }

  __routeChanged() {
    const navigationMenuNode = this.$.querySelector('golfleet-navigation-menu');
    const filterMenuNode = this.$.querySelector('golfleet-filter-menu');
    if (navigationMenuNode.hasAttribute('open')) navigationMenuNode.toggle();
    if (filterMenuNode.hasAttribute('open')) filterMenuNode.toggle();
  }

  __hasRequiredUpdateChanged(hasRequiredUpdate) {
    if (hasRequiredUpdate) this._onToggleAppInfo();
  }

  __privacyPolicyStatusChanged(needUserAccept) {
    if (needUserAccept) {
      this._interval = setInterval(() => {
        const privacyPolicyPopup = this.$.querySelector('#golfleet-popup-privacy-policy');

        if (privacyPolicyPopup) {
          privacyPolicyPopup.show();
          clearInterval(this._interval);
        }
      }, 300);
    }
  }

  __tokenChanged(token) {
    if (!token) {
      this.$state.go('login');
      this.$.removeAttribute('authorized');
      if (this.$.querySelector('golfleet-loader').toggle)
        this.$.querySelector('golfleet-loader').toggle(false);
    }
  }
  /* */
}

class GolfleetApp {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetAppController;
  }
}

app.component('golfleetApp', new GolfleetApp());
