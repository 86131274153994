import { compressState, decompressState } from '../utils/state-compression';

class Route {
  constructor(routeName = '', routeLink = '', routeTail = '', stateConfig = '', routeModule = '') {
    this.routeName = routeName;
    this.routeLink = routeLink;
    this.routeTail = routeTail;
    this.stateConfig = stateConfig;
    this.routeModule = routeModule;
  }
}

class State {
  constructor() {
    this.routeList = [];
    this.hasUpdate = false;
    this.obligatoryUpdate = false;
    this.nextRouteName = null;
    this.showHelp = null;
    this.applicationType = '';
    this.operationalSystem = '';
  }

  get storageId() {
    return 'stateReducer';
  }

  get storageType() {
    return 'sessionStorage';
  }

  get actions() {
    return {
      newRoute: 'NEW_ROUTE',
      nextRoute: 'NEXT_ROUTE',
      updateRoute: 'UPDATE_ROUTE',
      previousRoute: 'PREVIOUS_ROUTE',
      clearRouteList: 'CLEAR_ROUTE_LIST',
      nextRouteSingle: 'NEXT_ROUTE_SINGLE',
      updateUsageInformation: 'UPDATE_USAGE_INFORMATION',
    };
  }

  static NEW_ROUTE(state, data) {
    if ('routeName' in data) {
      window.dataLayer.push({ page_title: data.routeName });
    }

    if (
      'routeName' in data &&
      'routeLink' in data &&
      'routeTail' in data &&
      'stateConfig' in data &&
      'routeModule' in data
    ) {
      return Object.assign(new State(), state, {
        routeList: [
          new Route(
            data.routeName,
            data.routeLink,
            data.routeTail,
            data.stateConfig,
            data.routeModule,
          ),
        ],
      });
    }

    if (
      'routeName' in data &&
      'routeLink' in data &&
      'routeTail' in data &&
      'stateConfig' in data
    ) {
      return Object.assign(new State(), state, {
        routeList: [
          new Route(
            data.routeName,
            data.routeLink,
            data.routeTail,
            data.stateConfig,
            data.routeModule,
          ),
        ],
      });
    }
    return state;
  }

  static NEXT_ROUTE(state, data) {
    if ('routeName' in data) {
      window.dataLayer.push({ page_title: data.routeName });
    }

    if (
      'routeName' in data &&
      'routeLink' in data &&
      'routeTail' in data &&
      'stateConfig' in data &&
      'routeModule' in data
    ) {
      return Object.assign(new State(), state, {
        routeList: state.routeList.concat(
          new Route(
            data.routeName,
            data.routeLink,
            data.routeTail,
            data.stateConfig,
            data.routeModule,
          ),
        ),
      });
    }

    if (
      'routeName' in data &&
      'routeLink' in data &&
      'routeTail' in data &&
      'stateConfig' in data
    ) {
      return Object.assign(new State(), state, {
        routeList: state.routeList.concat(
          new Route(data.routeName, data.routeLink, data.routeTail, data.stateConfig),
        ),
      });
    }

    if ('routeName' in data && 'routeLink' in data && 'stateConfig' in data) {
      return Object.assign(new State(), state, {
        routeList: state.routeList.concat(new Route(data.routeName, data.routeLink, null, null)),
      });
    }
    return state;
  }

  static NEXT_ROUTE_SINGLE(state, data) {
    if ('routeName' in data) {
      window.dataLayer.push({ page_title: data.routeName });
    }

    if ('routeName' in data && 'routeLink' in data && 'stateConfig' in data) {
      state.nextRouteSingle = true;

      return Object.assign(new State(), state, {
        routeList: [new Route(data.routeName, data.routeLink, null, data.stateConfig)],
      });
    }

    return state;
  }

  static UPDATE_ROUTE(state, data) {
    return Object.assign(new State(), state, data);
  }

  static PREVIOUS_ROUTE(state, data) {
    if ('index' in data) {
      const routeList = state.routeList.splice(0, data.index + 1);
      const route = routeList[routeList.length - 1];

      window.dataLayer.push({
        page_title: route.routeName,
      });

      return Object.assign(new State(), state, {
        routeList,
      });
    }
    return state;
  }

  static CLEAR_ROUTE_LIST(state) {
    return Object.assign(new State(), state, { routeList: [] });
  }

  static UPDATE_USAGE_INFORMATION(state, data) {
    if ('applicationType' in data && 'operationalSystem' in data) {
      return Object.assign(new State(), state, {
        applicationType: data.applicationType,
        operationalSystem: data.operationalSystem,
      });
    }

    return state;
  }
}

class Session {
  constructor() {
    this.refreshFilter = false;
    this.updateLocalStorage = false;
    this.keepSession = false;
    this.token = '';
    this.userEmail = '';
    this.userName = '';
    this.companyName = '';

    this.userPhoto = '/assets/images/user-photo-placeholder.svg';
    this.headerIcon = '/assets/images/golfleet-driver-icon-color.svg';
    this.headerLogo = '/assets/images/golfleet.svg';
    this.headerIconAllowColorChange = true;
    this.tradeMarkIcon = '/assets/images/golfleet-icon.svg';
    this.deviceId = '';
    this.hasUpdate = false;
    this.hasRequiredUpdate = false;
    this.privacyPolicyStatus = {
      id: 0,
      url: '',
      version: '',
      needUserAccept: false,
    };
    this.modules = [];
  }

  get storageId() {
    return 'sessionReducer';
  }

  get storageType() {
    if (this._storageType !== 'sessionStorage' && this._storageType !== 'localStorage') {
      return 'sessionStorage';
    }

    return this._storageType;
  }

  set storageType(type) {
    if (type === 'sessionStorage' || type === 'localStorage') this._storageType = type;
  }

  get actions() {
    return {
      signin: 'SIGNIN',
      signout: 'SIGNOUT',
      appUpdated: 'APP_UPDATED',
      appHasUpdate: 'APP_HAS_UPDATE',
      updateModules: 'UPDATE_MODULES',
    };
  }

  static SIGNIN(state, data) {
    if ('companyName' in data) {
      window.dataLayer.push({
        company_name: data.companyName,
      });
    }
    if ('userEmail' in data) {
      window.dataLayer.push({
        user: data.userEmail,
      });
    }

    return Object.assign(new Session(), state, data, {
      storageType: data.keepSession && data.updateLocalStorage ? 'localStorage' : 'sessionStorage',
    });
  }

  static SIGNOUT() {
    return Object.assign(new Session());
  }

  static APP_UPDATED(state) {
    return Object.assign(state, { hasUpdate: false, hasRequiredUpdate: false });
  }

  static APP_HAS_UPDATE(state, data) {
    if ('hasRequiredUpdate' in data)
      return Object.assign(new Session(), state, data, { hasUpdate: true });
    return state;
  }

  static APP_HAS_PRIVACY_POLICY_UPDATE(state, data) {
    if ('privacyPolicyStatus' in data) {
      const { privacyPolicyStatus } = data;

      if (!!privacyPolicyStatus && 'version' in privacyPolicyStatus) {
        return Object.assign(new Session(), state, { privacyPolicyStatus });
      }
    }
    return state;
  }

  static APP_HAS_CHANGE_TOKEN(state, data) {
    if ('hasChangeToken' in data) return Object.assign(new Session(), state, data);
    return state;
  }

  static UPDATE_MODULES(state, data) {
    if ('modules' in data) return Object.assign(new Session(), state, data);
    return state;
  }
}

function getState(state) {
  const localState = window.localStorage.getItem(state.storageId);
  const sessionState = window.sessionStorage.getItem(state.storageId);

  const localCompressionLevel = window.localStorage.getItem(`${state.storageId}-compression-level`);
  const sessionCompressionLevel = window.sessionStorage.getItem(
    `${state.storageId}-compression-level`,
  );

  if (sessionState)
    return Object.assign(state, decompressState(sessionState, sessionCompressionLevel));
  if (localState) {
    window.sessionStorage.setItem(state.storageId, localState);
    window.sessionStorage.setItem(`${state.storageId}-compression-level`, localCompressionLevel);
    return Object.assign(state, decompressState(localState, localCompressionLevel));
  }

  return state;
}

function createReducer(StateClass) {
  return (state, action) => {
    if (!state) return getState(new StateClass());

    if (action.type in StateClass) {
      const newState = StateClass[action.type](state, action.data);
      const { result: compressedState, level: compressionLevel } = compressState(newState);

      window[newState.storageType].setItem(newState.storageId, compressedState);

      window[newState.storageType].setItem(
        `${newState.storageId}-compression-level`,
        compressionLevel,
      );

      if (action.type === 'SIGNOUT') {
        if (window.localStorage.getItem(newState.storageId)) {
          window.localStorage.setItem(newState.storageId, compressedState);
        }
        if (window.localStorage.getItem(`${newState.storageId}-compression-level`)) {
          window.localStorage.setItem(`${newState.storageId}-compression-level`, compressionLevel);
        }
      }

      return newState;
    }

    return state;
  };
}

const stateReducer = createReducer(State);
const sessionReducer = createReducer(Session);

export { stateReducer, sessionReducer };
