import angular from 'angular';
import 'ng-redux';

import '../golfleet-dropdown/golfleet-dropdown';

import template from './golfleet-history-menu.html';
import './golfleet-history-menu.scss';

class GolfleetHistoryMenuController {
  static get $inject() {
    return ['$element', '$ngRedux', '$state'];
  }

  constructor($element, $ngRedux, $state) {
    Object.assign(this, { $: $element[0], $ngRedux, $state });

    this.__golfleetBehavior = $ngRedux.connect(behavior =>
      Object({
        /* State Storage */
        routeList: behavior.state.routeList,
      }),
    )(this);
  }

  /* Lifecycle */
  $onDestroy() {
    this.__golfleetBehavior();
  }
  /* */

  backTo(index) {
    const backToState = this.reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  reverseRouteList() {
    return [...this.routeList].reverse();
  }
}

class GolfleetHistoryMenu {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetHistoryMenuController;
  }
}

angular
  .module('golfleet-history-menu', ['ngRedux', 'golfleet-dropdown'])
  .component('golfleetHistoryMenu', new GolfleetHistoryMenu());
