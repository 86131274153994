/* eslint-disable no-restricted-properties */
/* eslint-disable no-extend-native */

(() => {
  if (typeof window.formatNumber === 'function') return;

  function formatNumber(decimalLength, decimalMark, intergerMark) {
    decimalLength = decimalLength || decimalLength === 0 ? decimalLength : 2;
    intergerMark = intergerMark || '';
    decimalMark = decimalMark || '.';
    const sign = this < 0 ? '-' : '';
    const integer = parseInt(Math.abs(this).toFixed(decimalLength), 10);
    const decimalPrefix = Math.abs(this - integer).toFixed(decimalLength) < 0.1 ? '0' : '';
    const decimal = parseInt(
      Math.abs(this - integer).toFixed(decimalLength) * Math.pow(10, decimalLength),
      10,
    );
    const integerStr = String(integer)
      .split('')
      .reverse()
      .join('')
      .replace(/(\d{3})(?=\d)/g, `$1${intergerMark}`)
      .split('')
      .reverse()
      .join('');
    if (decimalLength === 0) return sign + integerStr;
    return sign + integerStr + decimalMark + decimalPrefix + decimal;
  }

  Number.prototype.formatNumber = formatNumber;
})();
