import angular from 'angular';
import moment from 'moment/src/moment';
import 'ng-redux';

import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';

import '../../directives/ng-resize/ng-resize';

import '../golfleet-fab/golfleet-fab';
import '../golfleet-popup/golfleet-popup';
import '../golfleet-footer/golfleet-footer';
import '../golfleet-toolbar/golfleet-toolbar';
import '../golfleet-dropdown/golfleet-dropdown';
import '../golfleet-pagination/golfleet-pagination';
import '../golfleet-header-selector/golfleet-header-selector';
import '../golfleet-map-utilization/golfleet-map-utilization';
import '../golfleet-grid-utilization/golfleet-grid-utilization';
import '../golfleet-pagination-register/golfleet-pagination-register';
import '../golfleet-card-utilization-list/golfleet-card-utilization-list';
import { GolfleetReportController } from '../golfleet-report/golfleet-report';
import { GetRouteConfig } from '../../configs/routes/routes.config';

import template from './golfleet-report-utilization.html';
import './golfleet-report-utilization.scss';

class GolfleetReportUtilizationController extends GolfleetReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
    );

    Object.assign(this, { $rootScope });

    this.legendList = [];
    this.selectedCards = [];
    this.reportMapDataset = [];

    this.mapModeList = [
      { type: 'route', icon: 'gs_pin_grid', description: 'Reconstrução de Rota', selected: true },
      { type: 'all', icon: 'gs_mapmode', description: 'Todos os Pontos', selected: false },
      { type: 'heat', icon: 'gs_heatmap', description: 'Mapa de Calor', selected: false },
    ];

    if (this.currentState.stateConfig.getDataFilters?.length == 0) {
      let interval;
      switch (this.currentState.routeLink) {
        case 'lastUtilizationReport':
        case 'todayUtilizationReport':
          interval = [0, 0];
          break;
        case 'lastFiveteenDaysUtilizationReport':
          interval = [14, 0];
          break;
        case 'lastThirtyDaysUtilizationReport':
          interval = [29, 0];
          break;
        default:
          interval = [0, 0];
      }

      const filters = [
        {
          field: 'date',
          condition: {
            preset: {
              interval,
              intervalType: 'days',
            },
          },
        },
      ];

      if (this.currentState.routeLink === 'lastUtilizationReport') {
        filters.push({
          field: 'onlyLastUtilization',
          default: true,
        });
      }

      const routeConfig = GetRouteConfig(this.currentState.routeLink);
      const result = {
        ...routeConfig.data,
        stateConfig: routeConfig.data.stateConfig({
          filters,
        }),
      };

      this.$state.go(this.currentState.routeLink, {}, { reload: true });

      this.$ngRedux.dispatch({
        type: 'NEXT_ROUTE_SINGLE',
        data: result,
      });
    }

    moment.locale('pt-BR');
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    const matchTablet = window.matchMedia('(max-width: 768px)');
    const matchTabletEvt = evt =>
      evt.matches && this.stateConfig.viewMode == 'split' ? this.changeView('grid') : null;
    matchTablet.addListener(matchTabletEvt);

    this.$.addEventListener('share', this.onShareEvent);
    this.$.addEventListener('delete', this.onDeleteEvent);
    this.$.addEventListener('move', this.onMoveEvent);

    this.$scope.$on('goToLink', this._goToLink.bind(this));
    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));
    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));

    this.$scope.$on('updateTimelineSlider', (evt, evtParams) =>
      this._updateTimelineSlider(evtParams.value),
    );

    this.changeView(this.currentState?.routeLink === 'lastUtilizationReport' ? 'map' : 'card');

    if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
        });
        this._getStateConfig();
      });
    } else {
      this.$scope.$on('getDatasetReady', () => {
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
          page: this.stateConfig.gridConfig.page,
          pageSize: this.stateConfig.gridConfig.pageSize,
        });

        this.$scope.$broadcast('getDataset', {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        });
      });
    }

    if (this.$.querySelector('#report-body-card')) {
      this.$.querySelector('#report-body-card').addEventListener(
        'selectRow',
        this.__onCardListSelectRow.bind(this),
      );

      this.$.querySelector('#report-body-card').addEventListener(
        'requestSyncVisualization',
        this.__onRequestSyncVisualization.bind(this),
      );
    }

    if (this.$.querySelector('#report-body-grid')) {
      this.$.querySelector('#report-body-grid').addEventListener(
        'requestSyncVisualization',
        this.__onRequestSyncVisualization.bind(this),
      );
    }
  }

  _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'route':
        mapComponent.removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'heatLayer',
          'stepMarker',
          'routeMarkers',
          'routeLinestrings',
        ]);
        mapComponent.toggleTimelineControl(true);

        this._renderRouteMode(this.reportMapDataset).then(() => {
          if (!this.timelineSlider)
            // eslint-disable-next-line no-unused-expressions
            this.reportMapDataset.length > 0
              ? this._createTimelineSlider(this.reportMapDataset.length - 1)
              : null;
          else
            this._updateTimelineSlider(
              this.reportMapDataset.length - 1,
              this.reportMapDataset.length - 1,
            );
        });
        break;
      case 'all':
        mapComponent.removeLayers(['heatLayer', 'stepMarker', 'routeMarkers', 'routeLinestrings']);
        mapComponent.toggleTimelineControl(false);
        this._renderAllMode(this.reportMapDataset);
        break;
      case 'heat':
        mapComponent.removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'stepMarker',
          'routeMarkers',
          'routeLinestrings',
        ]);
        mapComponent.toggleTimelineControl(false);
        this._renderHeatMode(this.reportMapDataset);
        break;
      case 'step':
        break;
      default:
        break;
    }
  }

  _getSelectedMapMode() {
    return this.mapModeList.find(mode => mode.selected);
  }

  async _renderAllMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');

    const parsedDataset = dataset.reduce((acc, data) => {
      switch (data.statusIgnicao) {
        case 1:
          return Object.assign(acc, {
            on: acc.on.concat(
              Object.assign(data, {
                color: '#4AAE4E',
                icon: data.iconeVeiculo || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        case 2:
          return Object.assign(acc, {
            iddle: acc.iddle.concat(
              Object.assign(data, {
                color: '#DECE00',
                icon: data.iconeVeiculo || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        case 3:
          return Object.assign(acc, {
            off: acc.off.concat(
              Object.assign(data, {
                color: '#980A1A',
                icon: data.iconeVeiculo || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        default:
          return Object.assign(acc, {
            invalid: acc.invalid.concat(
              Object.assign(data, {
                color: '#949494',
                icon: data.iconeVeiculo || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
      }
    }, Object({ on: [], iddle: [], off: [], invalid: [] }));

    await mapComponent.renderDataset({
      dataset: parsedDataset.on,
      layerName: 'allOn',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#4AAE4E',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.iddle,
      layerName: 'allIddle',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#DECE00',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.off,
      layerName: 'allOff',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#980A1A',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.invalid,
      layerName: 'allInvalid',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#949494',
    });

    mapComponent.resizeMap();
    mapComponent.fitLayers(['allOn', 'allIddle', 'allOff', 'allInvalid']);
  }

  async _renderHeatMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');
    await mapComponent.renderDataset({ dataset, layerName: 'heatLayer', type: 'HeatLayer' });
    mapComponent.resizeMap();
    mapComponent.fitLayers([], 'heatLayer');
  }

  async _renderStepMode(position) {
    const index = position == 0 ? 0 : position - 1;
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const dataPosition = this.reportDataset[index];
    this.selectedVehicle = dataPosition;

    switch (dataPosition.statusIgnicao) {
      case 1:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: dataPosition.iconeVeiculo || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 2:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: dataPosition.iconeVeiculo || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 3:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: dataPosition.iconeVeiculo || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      default:
        Object.assign(dataPosition, {
          color: '#949494',
          icon: dataPosition.iconeVeiculo || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
    }

    await mapComponent.renderDataset({
      dataset: [dataPosition],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  async _renderRouteMode(dataset) {
    if (dataset.length == 0) return;

    const [{ veiculoPossuiRotaPrecisa: serviceMatchRouteIsActive }] = dataset;

    if (serviceMatchRouteIsActive) {
      const gpxTemplate = (arr = []) => `<?xml version="1.0"?>
        <gpx version="1.0"
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xmlns="http://www.topografix.com/GPX/1/0"
          xsi:schemaLocation="http://www.topografix.com/GPX/1/0 http://www.topografix.com/GPX/1/0/gpx.xsd"
        >
          <trk>
            <trkseg>
              ${arr
                .map(
                  item =>
                    `<trkpt lat="${item.latitude.replace(',', '.')}" lon="${item.longitude.replace(
                      ',',
                      '.',
                    )}"/>`,
                )
                .join('\n        ')}
            </trkseg>
          </trk>
        </gpx>
      `;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      const request = arr =>
        this.$http({
          url: 'https://rme.cit.api.here.com/2/matchroute.json?routemode=car&app_id=Iajt61WMPoEvbbMdvMH8&app_code=Z7ncDhO8GxIIHGGDmzCkWQ',
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
            Accept: '*/*',
          },
          data: gpxTemplate(arr),
        }).then(result => {
          const markers = result.data.TracePoints.map((tracePoint, index, tracePoints) => ({
            ...dataset[index],
            latitude:
              tracePoint.confidenceValue > 0 ? tracePoint.latMatched : dataset[index].latitude,
            longitude:
              tracePoint.confidenceValue > 0 ? tracePoint.lonMatched : dataset[index].longitude,
            first: index === 0,
            last: index === tracePoints.length - 1,
            markerIcon: 'RouteIcon',
          }));

          const linestrings = [
            {
              geoJson: {
                type: 'LineString',
                coordinates: result.data.RouteLinks.reduce(
                  (acc, routeLink) => [...acc, ...routeLink.shape.split(' ')],
                  [],
                ).reduce((acc, routeLink, index, routeLinks) => {
                  if (index % 2 != 0) {
                    acc.push([routeLink, routeLinks[index - 1]]);
                  }
                  return acc;
                }, []),
              },
            },
          ];

          return {
            markers,
            linestrings,
          };
        });

      const { markers, linestrings } = await request(dataset);

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      this.$.querySelector('#report-body-map').setTimelineDataset({
        markers,
        linestrings,
      });
    } else {
      this.$.querySelector('#report-body-map').setTimelineDataset(
        dataset.reduce(
          (acc, ele, index, arr) => {
            if (index == 0)
              return Object.assign(acc, {
                markers: acc.markers.concat({
                  ...ele,
                  first: true,
                  last: false,
                  markerIcon: 'RouteIcon',
                }),
              });
            return Object.assign(acc, {
              markers: acc.markers.concat({
                ...ele,
                first: false,
                last: index == arr.length - 1,
                markerIcon: 'RouteIcon',
              }),
              linestrings: acc.linestrings.concat({
                geoJson: {
                  type: 'LineString',
                  coordinates: [
                    [
                      this._formatLatLng(arr[index - 1].longitude),
                      this._formatLatLng(arr[index - 1].latitude),
                    ],
                    [this._formatLatLng(ele.longitude), this._formatLatLng(ele.latitude)],
                  ],
                  properties: { dataHora: ele.dataHora },
                },
              }),
            });
          },
          { markers: [], linestrings: [] },
        ),
      );
    }
  }

  _createTimelineSlider(maxRange) {
    if (maxRange > 0) {
      this.timelineSlider = noUiSlider.create(this.$.querySelector('.timeline-slider'), {
        start: maxRange,
        connect: [true, false],
        range: { min: 0, max: maxRange },
        step: 1,
        behaviour: 'drag',
        tooltips: {
          from: value => parseInt(value, 10),
          to: value => {
            const index = value.toFixed(0);
            const lastIndex = this.reportMapDataset.length - 1;
            const data = this.reportMapDataset[index > lastIndex ? lastIndex : index];
            // Note: the Math.abs converts from negative to positive
            if (data) {
              const kmDiff =
                this.reportMapDataset.length > 0
                  ? Math.abs(this.reportMapDataset[0].odometro - data.odometro)
                  : 0;
              const currentDate = new Date(data.dataHora);
              const initDate = new Date(this.reportMapDataset[0].dataHora);
              const dateDiff = currentDate - initDate;
              const dateDiffHour = `${dateDiff / 3600000}`.split('.')[0];
              const dateDiffMinutes = `${dateDiff / 60000 - dateDiffHour * 60}`.split('.')[0];
              return `
                <div>
                  <i class="material-icons">gs_date</i>
                  <span>${new Date(data.dataHora).toLocaleString(navigator.language, {
                    hour12: false,
                  })}</span>
                </div>
                <div>
                  <i class="material-icons">gs_speed</i>
                  <span>${kmDiff.toFixed(2)} km percorridos</span>
                </div>
                <div>
                  <i class="material-icons">access_time</i>
                  <span>
                    ${dateDiffHour < 10 ? 0 : ''}${dateDiffHour}:${
                dateDiffMinutes < 10 ? 0 : ''
              }${dateDiffMinutes} percorridos
                  </span>
                </div>
              `;
            }
            return '';
          },
        },
      });
      this.timelineSlider.on('update', values =>
        this.$.querySelector('#report-body-map').updateTimelinePosition({
          pathPosition: parseInt(values[0], 10),
        }),
      );
    }
  }

  _updateTimelineSlider(value, maxRange) {
    if (maxRange) this.timelineSlider.updateOptions({ range: { min: 0, max: maxRange } });
    this.timelineSlider.set(value);
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }
  /* */

  /* Public */
  changePage(page) {
    this.$scope.$broadcast('changePage', {
      page,
      payload: {
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        navigation: {
          date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
        },
        isPaginated: this.stateConfig.gridConfig.backPagination,
        ...this.stateConfig.getDataFixedParams,
      },
    });
  }

  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    const mapComponent = this.$.querySelector('#report-body-map');
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('card-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      case 'card':
        this.$.setAttribute('card-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('card-view');
        this.$.removeAttribute('grid-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('card-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }

  changePageSize(pageSize) {
    this.$timeout(() => {
      this.$scope.$broadcast('changePageSize', {
        pageSize,
        payload: {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        },
      });
    });
  }

  requestDataset(keepPage) {
    const [calendarFilter] = this.stateConfig.filterConfig.filter(
      filter => filter.type === 'calendar',
    );

    if (
      this.stateConfig.navigation.date.length === 0 ||
      calendarFilter.condition.value[0] !== this.stateConfig.navigation.date[0].startDate ||
      calendarFilter.condition.value[0] !== this.stateConfig.navigation.date[0].endDate
    ) {
      this.stateConfig.navigation = {
        date: [
          {
            endDate: calendarFilter.condition.value[1],
            startDate: calendarFilter.condition.value[0],
            granularity: 'mes',
          },
        ],
        dateMinPath: 0,
      };
    }

    const payload = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
      navigation: {
        date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
      },
      isPaginated: this.stateConfig.gridConfig.backPagination,
    };

    if (!keepPage) payload.page = 1;

    this.$scope.$broadcast(
      'getDataset',
      Object.assign(payload, this.stateConfig.getDataFixedParams),
    );
  }
  /* */

  /* Private */
  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  async _changeActiveRow(page, rowPosition) {
    const gridElement = this.$.querySelector('golfleet-grid-utilization');

    this.changePage(page);
    gridElement.setActiveRow({ index: rowPosition - 1 });
  }

  _getActiveRow() {
    const gridElement = this.$.querySelector('golfleet-grid-utilization');
    const selectedIndex = gridElement.getActiveRow()._index;

    return selectedIndex < 0 ? 0 : selectedIndex + 1;
  }
  /* */

  /* Observers */
  __routeMarkerTapped(evt) {
    this.timelineSlider.set(evt.detail.pathPosition);
  }

  async __reportDatasetChanged(newValue) {
    if (newValue) {
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce((acc, ele) => {
        if (ele.icons && ele.icons.every(i => i.description || i.value)) {
          return acc.concat(ele);
        }
        return acc;
      }, []);
    }
  }

  __onCardListSelectRow(evt) {
    this.$.querySelector('#report-grid')?.selectRow(evt?.detail?.row);
  }

  async __onGetMapDataset(evt) {
    const { dataset } = evt.detail;
    this.reportMapDataset = dataset;

    this.mapModeList.forEach(mode => {
      mode.selected = false;
    });
  }

  __onResizeEnded() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
  }

  __onGetPositionsStart({ detail }) {
    const { splitPage } = detail;

    if (splitPage) {
      if (window.innerWidth > 768) {
        this.changeView('split');
      } else {
        this.changeView('map');
      }
    }

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __onGetPositionsEnd() {
    this._selectMapMode(this.mapModeList.find(mode => mode.type === 'route'));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: false },
        bubbles: true,
        composed: true,
      }),
    );
  }
  /* */
}

class GolfleetReportUtilization {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportUtilizationController;
  }
}

angular
  .module('golfleet-report-utilization', [
    'ngRedux',
    'ng-tippy',
    'ng-resize',
    'golfleet-fab',
    'golfleet-popup',
    'golfleet-footer',
    'golfleet-toolbar',
    'golfleet-dropdown',
    'golfleet-pagination',
    'golfleet-header-selector',
    'golfleet-map-utilization',
    'golfleet-grid-utilization',
    'golfleet-pagination-register',
    'golfleet-card-utilization-list',
  ])
  .component('golfleetReportUtilization', new GolfleetReportUtilization());
