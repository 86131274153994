import angular from 'angular';
import moment from 'moment/src/moment';
import * as Comlink from 'comlink';
import 'angular-sanitize';

import '../../directives/ng-resize/ng-resize';
import { GolfleetGridController } from '../golfleet-grid/golfleet-grid';

import template from './golfleet-grid-utilization.html';
import './golfleet-grid-utilization.scss';

class GolfleetGridUtilizationController extends GolfleetGridController {
  static get $inject() {
    return [
      '$element',
      '$rootScope',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $rootScope,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, {
      $: $element[0],
      $rootScope,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      $ngRedux,
    });

    this.__golfleetBehavior = $ngRedux.connect(behavior => {
      const { userEmail, userName } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userEmail, userName },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./golfleet-grid-utilization.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    this.activeRow = {};

    this.customActionsController = {
      showPositions: async ({ row, splitPage, scrollToIndex = false }) => {
        this.$.dispatchEvent(new CustomEvent('getPositionsStart', { detail: { splitPage } }));

        if (!row || row._active) {
          if (!row) {
            this.$.dispatchEvent(new CustomEvent('getMapDataset', { detail: { dataset: [] } }));
          }

          this.$.dispatchEvent(new CustomEvent('getPositionsEnd'));
          return;
        }

        await this.workerService.setActiveRow({ index: row._index });

        this.pageRows = await this.workerService.pageRows;
        this.activeRow = await this.workerService.activeRow;

        if (scrollToIndex) {
          this.$.querySelector('.table-container').scrollTo({
            top: row._index * 56,
            behavior: 'smooth',
          });
        }

        let dataset = [];

        const dataInitialDate = new Date(row.dataHoraIgnicaoLigada);
        const dataEndDate = new Date(row.dataHoraIgnicaoDesligada || row.dataHoraUltimaPosicao);

        const requestInitialDate = this._toDate(new Date(dataInitialDate));
        const requestEndDate = this._toDate(new Date(dataEndDate));

        this.$http({
          url: `${this.urlApi}/Utilizations/GetPositionsByUtilization`,
          method: 'POST',
          data: {
            request: {
              veiculoId: row.veiculoId,
              dataHoraInicial: requestInitialDate,
              dataHoraFinal: requestEndDate,
            },
          },
        })
          .then(success => {
            if (success.status && success.status === 200) {
              const response = success.data;
              dataset = response.data.positions;
            }
          })
          .finally(() => {
            this.$.dispatchEvent(new CustomEvent('getMapDataset', { detail: { dataset } }));
            this.$.dispatchEvent(new CustomEvent('getPositionsEnd'));

            if (this.queryMobile.matches) {
              requestAnimationFrame(() => this._virtualScrollRender());
            }

            this.$.dispatchEvent(
              new CustomEvent('requestSyncVisualization', { detail: { index: row._index } }),
            );
          });
      },
    };

    /* Virtual Pagination */
    this.firstVirtualPagination = true;
    this.virtualPaginationRequests = [];
    /* */

    moment.locale('pt-BR');
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getRows: this.getRows.bind(this),
      getHeaders: this.getHeaders.bind(this),
      getDataset: this.getDataset.bind(this),
      changePage: this.changePage.bind(this),
      scrollToIndex: this.scrollToIndex.bind(this),
      changePageSize: this.changePageSize.bind(this),
      getActiveRow: this.getActiveRow.bind(this),
      setActiveRow: this.setActiveRow.bind(this),
      getGridState: this.getGridState.bind(this),
      virtualPagination: this.virtualPagination.bind(this),
    });

    this.$scope.$on('getHeaders', this.getHeaders.bind(this));
    this.$scope.$on('getDataset', async (_, payload) => {
      const { page, pageSize } = payload;

      await this.workerService.setPageSize({ pageSize: pageSize ?? this.pageSize ?? 10 });
      await this.workerService.setPage({ page: page ?? this.page ?? 1 });

      this.page = await this.workerService.page;
      this.pageSize = await this.workerService.pageSize;
      this.virtualPaginationRequests = [];

      this.getDataset(this, { ...payload, resetDataset: true });
    });
    this.$scope.$on('changePage', this.changePage.bind(this));
    this.$scope.$on('changePageSize', this.changePageSize.bind(this));

    this.$scope.$watch(() => this.gridDataset, this.__onGridDatasetChanged.bind(this));
    this.$scope.$watch(() => this.dateGranularity, this.__onDateGranularityChanged.bind(this));
    this.$scope.$watch(
      () => JSON.stringify(this.gridHeaders),
      this.__onGridHeadersChanged.bind(this),
    );

    this.$scope.$emit('getDatasetReady');

    this.$.querySelector('.table-container').addEventListener(
      'scroll',
      this.__onVirtualScroll.bind(this),
      { passive: true },
    );

    this._lastPageSize = this.pageSize;

    this._performMobileModeAdjustments();

    this.queryMobile.addListener(this.__onMobileMediaQuery.bind(this));
  }

  $onDestroy() {
    super.$onDestroy();

    this.__golfleetBehavior();
  }
  /**/

  /* Public */
  async getRows(params = {}) {
    const { to = null, from = null } = params;

    const rows = await this.workerService.getRows({ to, from });

    return rows;
  }

  async changePage(_, { page, payload }) {
    if (this.queryMobile.matches) return;

    await this.workerService.setPage({ page });

    this.page = await this.workerService.page;
    this.pageSize = await this.workerService.pageSize;

    if (payload.isPaginated) {
      await this.getDataset(this, payload);
    } else {
      this.pageRows = await this.workerService.pageRows;
    }
  }

  async changePageSize(_, { pageSize, payload }) {
    await this.workerService.setPageSize({ pageSize });

    this.page = await this.workerService.page;
    this.pageSize = await this.workerService.pageSize;
    this.lastPage = await this.workerService.lastPage;
    this.virtualPaginationRequests = [];

    if (payload.isPaginated) {
      this.getDataset(this, { ...payload, resetDataset: true });
    } else {
      this.pageRows = await this.workerService.pageRows;
    }
  }

  async getGridState() {
    const page = await this.workerService.page;
    const pageSize = await this.workerService.pageSize;
    const datasetLength = await this.workerService.rowsLength;

    return { page, pageSize, datasetLength };
  }

  async virtualPagination(endNode, hideLoader) {
    const page = parseInt(endNode / this.pageSize);
    const requestList = [];

    if (this.isPaginated && this.page < this.lastPage && page < this.lastPage) {
      // Request Dataset Backward
      for (let backwardPage = page - 1; backwardPage > page - 6; backwardPage -= 1) {
        if (backwardPage > 0 && this.virtualPaginationRequests.includes(backwardPage) === false) {
          requestList.push(
            this.workerService.shouldRequest({ page: backwardPage }).then(shouldPaginate => {
              if (!shouldPaginate) return false;

              this.virtualPaginationRequests.push(backwardPage);

              return this.getDataset(this, {
                page: backwardPage,
                hideLoader: hideLoader ?? false,
                isPaginated: this.isPaginated,
              });
            }),
          );
        }
      }

      // Request Dataset Forward
      for (let forwardPage = page; forwardPage < page + 5; forwardPage += 1) {
        if (
          forwardPage <= this.lastPage &&
          this.virtualPaginationRequests.includes(forwardPage) === false
        ) {
          requestList.push(
            this.workerService.shouldRequest({ page: forwardPage }).then(shouldPaginate => {
              if (!shouldPaginate) return false;

              this.virtualPaginationRequests.push(forwardPage);

              return this.getDataset(this, {
                page: forwardPage,
                hideLoader: hideLoader ?? false,
                isPaginated: this.isPaginated,
              });
            }),
          );
        }
      }
    }

    return Promise.all(requestList);
  }

  async toggleAllRows() {
    const gridElement = this.$.querySelector('table');

    if (gridElement) {
      gridElement.setAttribute('pending', '');
    }

    await this.workerService.toggleAllRowsSelection();

    this.pageRows = await this.workerService.pageRows;
    this.selectedRows = await this.workerService.selectedRows;
    this.isAllRowsSelected = await this.workerService.isAllRowsSelected;

    this._requestUpdate();

    if (gridElement) {
      gridElement.removeAttribute('pending');
    }
  }

  getDataset(_, payload) {
    let resetDataset = false;

    if (!payload || this.awaitNextPage === false || this.page >= payload.page) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
    }

    this.$scope.$emit('finishFilterConditions', {});

    if (payload) {
      if (this.page >= payload.page) {
        resetDataset = true;
        this.$.querySelector('.table-container').scrollTo(0, 0);
      }

      Object.assign(this, {
        page: payload.page >= 0 ? payload.page : this.page,
        isPaginated: payload.isPaginated,
        datasetParams: { ...this.datasetParams, ...payload },
      });

      if (payload.sort) {
        const { name, direction } = payload.sort;

        this.sortHeader = name;
        this.sortDirection = direction;

        this.gridHeaders = this.gridHeaders.map(gridHeader => {
          if (
            (gridHeader.columns &&
              gridHeader.columns.some(
                column => column.sortable === name || column.field === name,
              )) ||
            gridHeader.sortable === name ||
            gridHeader.field === name
          ) {
            gridHeader.show = true;

            if (gridHeader.columns && gridHeader.selectors) {
              const column = gridHeader.columns.find(
                item => item.sortable === name || item.field === name,
              );
              gridHeader.selectors.forEach(item => {
                item.selected = item.selector === column.selector;
              });
            }
          }

          return gridHeader;
        });
      }

      if (payload.visibleColumns) {
        const { visibleColumns } = payload;

        this.gridHeaders = this.gridHeaders.map(gridHeader => {
          if (
            (gridHeader.columns &&
              gridHeader.columns.some(column => visibleColumns.includes(column.field))) ||
            visibleColumns.includes(gridHeader.field)
          ) {
            gridHeader.show = true;

            if (gridHeader.columns && gridHeader.selectors) {
              const column = gridHeader.columns.find(item => visibleColumns.includes(item.field));
              if (gridHeader.selectors) {
                gridHeader.selectors.forEach(item => {
                  item.selected = item.selector === column.selector;
                });
              }
            }
          }

          return gridHeader;
        });
      }
    } else {
      resetDataset = true;
      this.$.querySelector('.table-container').scrollTo(0, 0);
    }

    return this.$http({
      url: `${this.urlApi}/${this.datasetMethod}`,
      method: 'POST',
      data: {
        request: {
          ...this.datasetParams,
          page: this.page - 1,
          length: this.pageSize,
          sort: {
            name: this.sortHeader,
            direction: this.sortDirection,
          },
        },
      },
    })
      .then(
        async success => {
          if (success.status && success.status === 200) {
            const columns = await this.workerService.columns;
            const response = success.data.data;

            if (columns.length === 0) {
              await this.workerService.setColumns({ columns: this.gridHeaders });
            }

            this.datasetLength = response.total;
            this.lastPage = this._calcGridMaxPage(response.total, this.pageSize);
            this.page = (this.page > this.lastPage ? 1 : this.page) || 1;

            await this.workerService.setPageSize({ pageSize: this.pageSize });
            await this.workerService.setPage({ page: this.page });

            if (this.datasetLength === 0) {
              this.virtualRows = [];
            }

            if (this.queryMobile.matches) {
              await this.workerService.addToDataset({
                page: this.page - 1,
                rows: response.data,
                rowsLength: response.total,
                isPaginated: this.isPaginated,
                resetDataset,
                useVirtualScroll: true,
              });
            } else {
              await this.workerService.setDataset({
                page: this.page - 1,
                rows: response.data,
                rowsLength: response.total,
                isPaginated: this.isPaginated,
                resetDataset,
              });
            }

            this.page = await this.workerService.page;
            this.pageSize = await this.workerService.pageSize;
            this.lastPage = await this.workerService.lastPage;
            this.pageRows = await this.workerService.pageRows;
            this.gridDataset = await this.workerService.rows;
            this.selectedRows = await this.workerService.selectedRows;
            this.toggleAllIcon = await this.workerService.toggleAllIcon;
            this.isAllSelected = await this.workerService.isAllSelected;

            this._requestUpdate();

            this.$scope.$emit('loadDatasetComplete');
          }
        },
        async () => {
          await this.workerService.setPageSize({ pageSize: this.pageSize });
          await this.workerService.setPage({ page: 1 });
          await this.workerService.setDataset({ rows: [], isPaginated: this.isPaginated });

          this.page = await this.workerService.page;
          this.pageSize = await this.workerService.pageSize;
          this.lastPage = await this.workerService.lastPage;
          this.pageRows = await this.workerService.pageRows;
          this.gridDataset = await this.workerService.rows;
          this.selectedRows = await this.workerService.selectedRows;
          this.toggleAllIcon = await this.workerService.toggleAllIcon;
          this.isAllSelected = await this.workerService.isAllSelected;

          this._requestUpdate();
        },
      )
      .finally(() => {
        this.$timeout(() => this.$scope.$emit('UPDATE_ROUTE'));

        this.$.dispatchEvent(
          new CustomEvent('getDataset', {
            detail: {
              page: payload?.page || this.page,
              resetDataset: resetDataset || this.firstVirtualPagination,
              datasetLength: this.datasetLength,
            },
          }),
        );

        this.firstVirtualPagination = false;

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );

        this.customActionsController.showPositions({
          row: this.pageRows[0],
          splitPage: false,
        });

        this._requestUpdate();
      });
  }

  getActiveRow() {
    return this.activeRow;
  }

  async setActiveRow({ row, index }) {
    if (row) {
      this.customActionsController.showPositions({
        row,
        splitPage: false,
        scrollToIndex: true,
      });
    } else if (index >= 0) {
      const [_row] = await this.workerService.getRows({
        to: parseInt(index) + 1,
        from: parseInt(index),
      });

      this.customActionsController.showPositions({
        row: _row,
        splitPage: false,
        scrollToIndex: true,
      });
    }
  }

  async scrollToIndex(index) {
    this.$.querySelector('.table-container').scrollTo({
      top: index * 56,
      behavior: 'smooth',
    });

    const [row] = await this.workerService.getRows({
      to: parseInt(index) + 1,
      from: parseInt(index),
    });

    this.customActionsController.showPositions({ row, splitPage: false });
  }
  /* */

  /* Private */
  _customAction(options) {
    const defaults = { action: '', params: {}, data: {} };
    const result = Object.assign(defaults, options);

    const customAction = this.customActionsController[result.action];
    if (customAction) {
      customAction({ row: result.data, ...result.params });
    }
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  _selectRow(column, row) {
    const result =
      super._selectRow(column, row) &&
      (!Object.prototype.hasOwnProperty.call(row, column.field) || !!row[column.field]);

    return result;
  }
  /* */

  /* Observers */
  async __onGridDatasetChanged(gridDataset) {
    if (!gridDataset || gridDataset.length == 0) {
      this.$.setAttribute('empty', '');
      this._resetScrollPosition();
    } else {
      this.$.removeAttribute('empty');

      if (this.queryMobile.matches) {
        requestAnimationFrame(() => this._virtualScrollRender());
      }
    }
  }
  /* */
}

class GolfleetGridUtilization {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridUtilizationController;
  }
}

angular
  .module('golfleet-grid-utilization', ['ngSanitize', 'ng-resize'])
  .component('golfleetGridUtilization', new GolfleetGridUtilization());
