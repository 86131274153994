/* eslint-disable guard-for-in */
import angular from 'angular';
import 'ng-redux';
import { isMobile } from 'mobile-device-detect';

import template from './golfleet-navigation-menu.html';
import './golfleet-navigation-menu.scss';

class GolfleetNavigationMenuController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$rootScope',
      '$scope',
      '$http',
      'urlApi',
      'version',
      'commonServices',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $rootScope,
    $scope,
    $http,
    urlApi,
    version,
    commonServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $rootScope,
      $scope,
      $http,
      urlApi,
      version,
      commonServices,
    });

    this.__golfleetBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Reducer */
        userName: behavior.session.userName,
        hasUpdate: behavior.session.hasUpdate,
        companyName: behavior.session.companyName,
        isConnected: !!behavior.session.token,
        routeList: behavior.state.routeList,
        currentState: behavior.state.routeList[behavior.state.routeList.length - 1],
        modules: behavior.session.modules,
      }),
    )(this);

    this.applicationName = 'Golfleet Driver';
    this.menuNavigationList = [];
    this.isMobile = isMobile;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      toggleSubRoute: this.toggleSubRoute.bind(this),
    });
    this.$rootScope.$on('updateModules', (event, data) => {
      this.modules = data;
      this._getMenuConfiguration();
    });

    this._getMenuConfiguration();
  }

  $doCheck() {
    requestAnimationFrame(() => this.__routeChanged());
  }

  $onDestroy() {
    this.__golfleetBehavior();
  }
  /* */

  /* Public */
  toggle() {
    if (!this.$.hasAttribute('open')) {
      this.$.setAttribute('open', '');
    } else {
      this.$.removeAttribute('open');
      this.menuNavigationList.map(route =>
        this.$.querySelector(`[data-id="${route.id}"]`).removeAttribute('open'),
      );
    }
  }

  toggleSubRoute(routeId, open) {
    const routeButton = this.$.querySelector(`[data-id="${routeId}"]`);

    if (!open && routeButton.hasAttribute('open')) {
      routeButton.removeAttribute('open');
    } else {
      routeButton.setAttribute('open', '');
    }
  }
  /* */

  /* Private */
  _newRoute(route) {
    if (!route.route) {
      if (this.$.hasAttribute('open') === false) {
        this.$.setAttribute('open', '');
      }
      this.toggleSubRoute(route.id);
    } else if (!route.identificator || route.identificator == 5) {
      this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
      this.$state.go(
        route.route,
        {
          // Addon Services
          routeId: route.id,
          serviceName: route.description,
          serviceIcon: route.icon,
        },
        { reload: true },
      );
    } else if (route.type == 4) {
      this.$state.go(route.route);
    }
  }

  _toggleVersionPopup() {
    document.querySelector('#popup-app-info').toggle();
  }

  _getMenuConfiguration() {
    this.commonServices.getMenuOptions().then(response => {
      const menus = response.filter(
        r => !r.moduleValidation || this.modules.find(m => m === r.moduleValidation),
      );
      this.menuNavigationList = menus;
    });
  }
  /* */

  /* Observers */
  __routeChanged() {
    const [firstRoute] = this.routeList;
    const [lastRoute] = [...this.routeList].reverse();

    const selectRoute = routeId => {
      this.$.querySelectorAll('[selected]').forEach(node => {
        node.removeAttribute('selected');
      });

      this.$.querySelector(`[data-id="${routeId}"]`).setAttribute('selected', '');
    };

    const searchRoute = route => {
      for (const navigationRoute of this.menuNavigationList) {
        const node = this.$.querySelector(`[data-id="${navigationRoute.id}"]`);

        if (!node) return;

        if (
          route &&
          (navigationRoute.route == route.routeLink ||
            (navigationRoute && navigationRoute.id === (route.stateConfig || {}).routeId))
        ) {
          selectRoute(navigationRoute.id);
          break;
        } else if (
          route &&
          navigationRoute.itens?.length > 0 &&
          navigationRoute.itens.filter(subRoute => subRoute.route == route.routeLink).length == 1
        ) {
          selectRoute(navigationRoute.id);
          break;
        }
      }
    };

    searchRoute(firstRoute);

    if (this.routeList > 1) {
      searchRoute(lastRoute);
    }
  }
  /* */
}

class GolfleetNavigationMenu {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.transclude = {
      supportSlot: '?supportSlot',
    };
    this.controller = GolfleetNavigationMenuController;
  }
}

angular
  .module('golfleet-navigation-menu', ['ngRedux'])
  .component('golfleetNavigationMenu', new GolfleetNavigationMenu());
