import angular from 'angular';

import { GolfleetCardListController } from '../golfleet-card-list/golfleet-card-list';

import template from './golfleet-card-utilization-list.html';
import './golfleet-card-utilization-list.scss';

class GolfleetCardUtilizationListController extends GolfleetCardListController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$ngRedux', '$state'];
  }

  constructor($element, $scope, $rootScope, $ngRedux, $state) {
    super($element, $scope, $rootScope, $state);

    Object.assign(this, { $: $element[0], $scope, $rootScope, $ngRedux, $state });
  }

  $onInit() {
    super.$onInit();
  }

  scrollToIndex(index) {
    const _index = parseInt(index);

    if (_index <= 0) {
      this.cardIndex = 0;
    } else if (_index >= this.datasetLength) {
      this.cardIndex = this.datasetLength - 1;
    } else {
      this.cardIndex = _index;
    }

    this.$.querySelector('.mobile-card-list-container').scrollTo({
      left: 48 + this.cardIndex * 16 + (75 * window.innerWidth * this.cardIndex) / 100,
      behavior: 'smooth',
    });
  }

  async _virtualScrollRender() {
    const gridElement = document.querySelector('#report-body-grid');
    const scrollableElement = this.$.querySelector('.mobile-card-list-container');
    const hideVirtualPaginationLoader = this.virtualCards.length !== 0;

    let offsetX = 0;
    let endNode = 0;
    let startNode = 0;

    if (!scrollableElement) return { startNode, endNode };

    const { page, pageSize, datasetLength } = await gridElement.getGridState();
    const cardWidth = 16 + (75 * window.innerWidth) / 100;
    const nodePadding = 3;
    const visibleNodesCount = 1 + 2 * nodePadding;
    const expectedScrollPosition = cardWidth * (page - 1) * pageSize;

    if (
      this.firstVirtualCardListRender &&
      page > 1 &&
      scrollableElement.scrollLeft < expectedScrollPosition
    ) {
      this.$.style.setProperty(
        '--virtual-scroll-width',
        `${parseInt(datasetLength * cardWidth)}px`,
      );

      scrollableElement.scrollTo(expectedScrollPosition, 0);

      startNode = Math.floor(scrollableElement.scrollLeft / cardWidth) - nodePadding;
      startNode = Math.max(0, startNode);
      endNode = startNode + visibleNodesCount;

      this.firstVirtualCardListRender = false;

      return { startNode, endNode };
    }

    startNode = Math.floor(scrollableElement.scrollLeft / cardWidth) - nodePadding;

    startNode = Math.max(0, startNode);
    offsetX = startNode * cardWidth;
    endNode = startNode + visibleNodesCount;

    this.virtualCards = await gridElement.getRows({
      to: endNode,
      from: startNode,
    });

    this.$.style.setProperty('--virtual-scroll-offset', `${offsetX}px`);
    this.$.style.setProperty('--virtual-scroll-width', `${parseInt(datasetLength * cardWidth)}px`);

    if (this.queryMobile.matches) {
      gridElement.virtualPagination(endNode, hideVirtualPaginationLoader);
    }

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }

    return { startNode, endNode };
  }

  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _formatDate(date) {
    return this._toDate(date).toLocaleString(navigator.language, { hour12: false });
  }

  _getFormatedDate(date) {
    return this._formatDate(date).replace(',', ' ').split(' ')[0];
  }

  _getFormatedHour(date) {
    return this._formatDate(date).replace(',', ' ').split(' ')[1];
  }

  _numberPadStart(value, size, character) {
    return `${value}`.padStart(size, character);
  }

  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  _getStreetViewLink(latitude, longitude) {
    return `https://maps.google.com/maps?layer=c&q=${latitude},${longitude}&cbll=${latitude},${longitude}&cbp=11,0,0,0,0&z=17&ll=${latitude},${longitude}`;
  }
}

class GolfleetCardUtilizationList {
  constructor() {
    this.template = template;
    this.bindings = {
      pageRows: '=?',
      gridHeaders: '=?',
      hasRowSelection: '=?',
    };
    this.controller = GolfleetCardUtilizationListController;
  }
}

angular
  .module('golfleet-card-utilization-list', [])
  .component('golfleetCardUtilizationList', new GolfleetCardUtilizationList());

export { GolfleetCardUtilizationListController, GolfleetCardUtilizationList };
