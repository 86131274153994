import angular from 'angular';
import moment from 'moment/src/moment';
import 'moment/src/locale/pt-br';

import template from './golfleet-pagination-register.html';
import './golfleet-pagination-register.scss';

class GolfleetPaginationRegisterController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux'];
  }

  constructor($element, $scope, $ngRedux) {
    Object.assign(this, { $: $element[0], $scope, $ngRedux });

    this.queryMobile = window.matchMedia('(max-width: 425px)');

    moment.locale('pt-BR');
  }

  $onDestroy() {}

  changePage(toPage) {
    if (toPage > 0 && toPage <= this.lastPage) {
      this.changeRegisterFunction({ toPage });
    }
  }

  changeRow(toRow) {
    const toPage = Math.ceil(toRow / this.pageSize);

    if (toPage > 0 && toPage <= this.lastPage) {
      this.changeRegisterFunction({ page: toPage, row: toRow });
    }
  }

  toggleCard() {
    const cardElement = this.$.querySelector('.golfleet-register-card');

    if (cardElement.hasAttribute('open')) {
      cardElement.removeAttribute('open');
    } else {
      cardElement.setAttribute('open', '');
    }
  }

  _getStreetViewLink(latitude, longitude) {
    return `https://maps.google.com/maps?layer=c&q=${latitude},${longitude}&cbll=${latitude},${longitude}&cbp=11,0,0,0,0&z=17&ll=${latitude},${longitude}`;
  }

  _rowOnPage() {
    return this.actualRow;
  }

  _rowOnPageFormatted() {
    return `${this.actualRow}`.padStart(2, '0');
  }

  _actualPageFormatted() {
    return `${this.actualPage}`.padStart(2, '0');
  }

  _actualRowData() {
    return (this.pageRows || [])[this.actualRow - 1];
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _formatDate(date) {
    return this._toDate(date).toLocaleString(navigator.language, { hour12: false });
  }

  _getFormatedDate(date) {
    return this._formatDate(date).replace(',', ' ').split(' ')[0];
  }

  _getFormatedHour(date) {
    return this._formatDate(date).replace(',', ' ').split(' ')[1];
  }
}

class GolfleetPaginationRegister {
  constructor() {
    this.template = template;
    this.bindings = {
      pageSize: '=?',
      actualRow: '=?',
      lastRow: '=?',
      actualPage: '=',
      lastPage: '=',
      pageRows: '=',
      changeRegisterFunction: '&',
    };
    this.controller = GolfleetPaginationRegisterController;
  }
}

angular
  .module('golfleet-pagination-register', [])
  .component('golfleetPaginationRegister', new GolfleetPaginationRegister());
