import {
  DashStateConfig,
  ReportStateConfig,
} from '../../components/golfleet-state-config/golfleet-state-config';

const RoutesConfig = {
  /* Dashboards */
  dashboard: {
    name: 'dashboard',
    url: '/dashboard',
    template: '<golfleet-dashboard></golfleet-dashboard>',
    params: {},
    data: {
      routeName: 'Dashboard',
      routeLink: 'dashboard',
      routeTail: null,
      stateConfig: () => new DashStateConfig({ screenName: 'Dashboard', toolbarName: 'Dashboard' }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  /* Reports */
  lastUtilizationReport: {
    name: 'lastUtilizationReport',
    url: '/utilizacao/relatorio/ultima',
    template: '<golfleet-report-utilization></golfleet-report-utilization>',
    params: {
      filters: [],
    },
    data: {
      routeName: 'Última Utilização',
      routeLink: 'lastUtilizationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Utilization',
          toolbarName: 'Report',
          getDataMethod: 'Utilizations/GetUtilizations',
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },

  todayUtilizationReport: {
    name: 'todayUtilizationReport',
    url: '/utilizacao/relatorio/hoje',
    template: '<golfleet-report-utilization></golfleet-report-utilization>',
    params: {
      filters: [],
    },
    data: {
      routeName: 'Utilizações de Hoje',
      routeLink: 'todayUtilizationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Utilization',
          toolbarName: 'Report',
          getDataMethod: 'Utilizations/GetUtilizations',
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
  lastFiveteenDaysUtilizationReport: {
    name: 'lastFiveteenDaysUtilizationReport',
    url: '/utilizacao/relatorio/15-dias',
    template: '<golfleet-report-utilization></golfleet-report-utilization>',
    params: {
      filters: [],
    },
    data: {
      routeName: 'Últimos 15 dias',
      routeLink: 'lastFiveteenDaysUtilizationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Utilization',
          toolbarName: 'Report',
          getDataMethod: 'Utilizations/GetUtilizations',
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
  lastThirtyDaysUtilizationReport: {
    name: 'lastThirtyDaysUtilizationReport',
    url: '/utilizacao/relatorio/30-dias',
    template: '<golfleet-report-utilization></golfleet-report-utilization>',
    params: {
      filters: [],
    },
    data: {
      routeName: 'Últimos 30 dias',
      routeLink: 'lastThirtyDaysUtilizationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          viewMode: 'grid',
          gridName: 'Report',
          screenName: 'Utilization',
          toolbarName: 'Report',
          getDataMethod: 'Utilizations/GetUtilizations',
          backPagination: false,
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
  violationReport: {
    name: 'violationReport',
    url: '/infracoes/relatorio',
    template: '<golfleet-chart-report></golfleet-chart-report>',
    params: {
      filters: [],
    },
    data: {
      routeName: 'Histórico de Infrações',
      routeLink: 'violationReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'ChartReport',
          screenName: 'Violations',
          toolbarName: 'ChartReport',
          getDataMethod: 'Violation/GetViolations',
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
  travelReport: {
    name: 'travelReport',
    url: '/rodagem/relatorio',
    template: '<golfleet-chart-report></golfleet-chart-report>',
    params: {
      filters: [],
    },
    data: {
      routeName: 'Histórico de Rodagem',
      routeLink: 'travelReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'ChartReport',
          screenName: 'Travel',
          toolbarName: 'ChartReport',
          getDataMethod: 'Travel/GetTravels',
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
  rankingReport: {
    name: 'rankingReport',
    url: '/ranking/relatorio',
    template: '<golfleet-report-ranking></golfleet-report-ranking>',
    params: {
      filters: [],
    },
    data: {
      routeName: 'Ranking de Infrações',
      routeLink: 'rankingReport',
      routeTail: null,
      stateConfig: $stateParams =>
        new ReportStateConfig({
          type: 'Report',
          screenName: 'Ranking',
          toolbarName: 'Report',
          getDataMethod: 'Ranking/GetRankings',
          getDataFilters: $stateParams.filters,
          getDataFixedParams: {},
        }),
    },
    __metadata__: {
      icon: '',
      isCustomizable: false,
      exportMethod: '',
    },
  },
};

const GetRouteConfig = routeName => RoutesConfig[routeName];

export { RoutesConfig, GetRouteConfig };
