import * as Sentry from '@sentry/browser';

window.Sentry = Sentry;

const updateSentryScope = event => {
  const { user, extra } = event.detail;

  Sentry.configureScope(scope => {
    if (user && user.email) {
      scope.setUser({ email: user.email });
    }

    Object.keys(extra).forEach(key => {
      if (extra[key] !== undefined) {
        scope.setExtra(key, extra[key]);
      }
    });
  });
};

// eslint-disable-next-line no-constant-condition
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e2cb9a0d083b4ffaa3758c0b5918f019@o420257.ingest.sentry.io/5338184',
    release: 'process.app.version',
    blacklistUrls: ['maps.api.here'],
  });
  Sentry.configureScope(scope => {
    scope.setTag('app version', 'process.app.version');
    scope.setTag('enviroment', 'process.app.enviroment');
    scope.setLevel('error');
  });

  window.addEventListener('updateSentryScope', updateSentryScope);
}
