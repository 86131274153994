import angular from 'angular';
import moment from 'moment/src/moment';
import 'moment/src/locale/pt-br';
import 'angular-drag-and-drop-lists';

import { FilterCondition } from '../golfleet-filter/golfleet-filter';
import { ReportStateConfig } from '../golfleet-state-config/golfleet-state-config';
import { GetRouteConfig } from '../../configs/routes/routes.config';

class GolfleetReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
    });

    this.__golfleetBehavior = $ngRedux.connect(behavior => {
      const routeListSize = behavior.state.routeList.length;
      const currentState = behavior.state.routeList[routeListSize - 1];
      return Object({
        /* State Storage */
        lastState: routeListSize > 1 ? behavior.state.routeList[routeListSize - 2] : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        isDrillDownRoute: routeListSize > 1,
      });
    })(this);

    this.toastText = '';
    this.gridSizeList = [10, 20, 30, 50];
    this.selectedRows = [];
    this.closeReportControlMoreOptions = false;

    this.queryMobile = window.matchMedia('(max-width: 425px)');

    moment.locale('pt-BR');
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    const matchTablet = window.matchMedia('(max-width: 768px)');
    const matchTabletEvt = evt =>
      evt.matches && this.stateConfig.viewMode == 'split' ? this.changeView('grid') : null;
    matchTablet.addListener(matchTabletEvt);

    this.$.addEventListener('move', this.onMoveEvent);
    this.$.addEventListener('share', this.onShareEvent);
    this.$.addEventListener('delete', this.onDeleteEvent);

    this.$scope.$on('goToLink', this._goToLink.bind(this));
    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this.changeView('grid');

    if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
        });
        this._getStateConfig();
      });
    } else {
      this.$scope.$on('getDatasetReady', () => {
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
          page: this.stateConfig.gridConfig.page,
          pageSize: this.stateConfig.gridConfig.pageSize,
        });

        const dateControl = this.$.querySelector('golfleet-date-control');

        if (dateControl) {
          const [dateCondition] = this.stateConfig.filterConfig.filter(
            filter => filter.type === 'calendar',
          );
          dateControl.updateDateValue(dateCondition);
        } else {
          this.$scope.$broadcast('getDataset', {
            filter: {
              conditions: this.stateConfig.filterConditions,
            },
            isPaginated: this.stateConfig.gridConfig.backPagination,
            ...this.stateConfig.getDataFixedParams,
          });
        }
      });
    }

    if (this.$.querySelector('#report-body-card')) {
      this.$.querySelector('#report-body-card').addEventListener(
        'requestSyncVisualization',
        this.__onRequestSyncVisualization.bind(this),
      );
    }

    if (this.$.querySelector('#report-body-grid')) {
      this.$.querySelector('#report-body-grid').addEventListener(
        'requestSyncVisualization',
        this.__onRequestSyncVisualization.bind(this),
      );
    }
  }

  $onDestroy() {
    this.__golfleetBehavior();
    this.$.removeEventListener('share', this.onDeleteEvent);
  }
  /* */

  /* Public */
  requestDataset(keepPage) {
    const payload = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
      isPaginated: this.stateConfig.gridConfig.backPagination,
    };

    if (!keepPage) payload.page = 1;

    this.$scope.$broadcast(
      'getDataset',
      Object.assign(payload, this.stateConfig.getDataFixedParams),
    );
  }

  changePage(page) {
    this.$scope.$broadcast('changePage', {
      page,
      payload: {
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        isPaginated: this.stateConfig.gridConfig.backPagination,
        ...this.stateConfig.getDataFixedParams,
      },
    });
  }

  changePageSize(pageSize) {
    this.$timeout(() => {
      this.$scope.$broadcast('changePageSize', {
        pageSize,
        payload: {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        },
      });
    });
  }

  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    const mapComponent = this.$.querySelector('#report-body-map');
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }
  /* */

  /* Private */
  _amountShowingHeaders() {
    return this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders
      ? this.stateConfig.gridConfig.gridHeaders.filter(ele => ele.show).length
      : 0;
  }

  _errorAddItem() {
    this.toastText = 'Por favor verifique os campos';
    this.$.querySelector('golfleet-toast#report-toast').toggle(true);
  }

  _getStateConfig() {
    this.filterServices
      .getPowerFilters(this.stateConfig.screenName, this.stateConfig.gridName)
      .then(getFiltersResult => {
        const getToolbarConfigAndApplyFilters = () => {
          this.commonServices
            .getToolbarOptions(
              this.stateConfig.screenName,
              this.stateConfig.toolbarName,
              getToolbarResult => {
                const {
                  addConfig,
                  editConfig,
                  viewsConfig,
                  exportConfig,
                  importConfig,
                  actionConfig,
                  saveConfig,
                  autoSendConfig,
                } = getToolbarResult;
                Object.assign(this.stateConfig.toolbarConfig, {
                  addConfig,
                  editConfig,
                  viewsConfig,
                  exportConfig,
                  importConfig,
                  actionConfig,
                  saveConfig,
                  autoSendConfig,
                });
                this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
              },
            )
            .then(() =>
              setTimeout(() => {
                const filterMenuElement = document
                  .querySelector('golfleet-app')
                  .querySelector('golfleet-filter-menu');

                filterMenuElement.applyFilters();
              }, 300),
            );
        };

        if (getFiltersResult) {
          const { drilldownMap } = getFiltersResult;
          const filterConfigList = getFiltersResult.filters;
          this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
            Object.clone({}, filterConfig),
          );
          if (
            this.lastState &&
            drilldownMap.length > 0 &&
            this.lastState.stateConfig.filterConfig
          ) {
            const lastFilterConfigList = this.lastState.stateConfig.filterConfig;
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const drilldown = drilldownMap.filter(
                drilldown2 => drilldown2.toId == actualFilter.id,
              )[0];
              if (drilldown) {
                const previousFilter = lastFilterConfigList.filter(
                  filter => filter.id == drilldown.fromId,
                )[0];

                if (
                  previousFilter &&
                  previousFilter.type === 'calendar' &&
                  previousFilter.activeView === 'preset' &&
                  previousFilter.condition &&
                  previousFilter.condition.preset
                ) {
                  const result = {
                    ...actualFilter,
                    activeView: 'calendar',
                    condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                  };

                  result.condition.preset = null;
                  result.condition.value = this._convertCalendarPresetToDates(
                    previousFilter.condition.preset,
                  );

                  return result;
                }
                return {
                  ...actualFilter,
                  condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                };
              }
              return actualFilter;
            });
          }

          if (this.stateConfig.getDataFilters && this.stateConfig.getDataFilters.length > 0) {
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const fixedFilters = this.stateConfig.getDataFilters.filter(
                condition =>
                  condition.id == actualFilter.id ||
                  (!!actualFilter.field && condition.field == actualFilter.field),
              )[0];

              return fixedFilters
                ? {
                    ...actualFilter,
                    condition: new FilterCondition(
                      actualFilter.id,
                      actualFilter.field ||
                        (
                          actualFilter.filters[fixedFilters.activeEntity] ||
                          actualFilter.filters[fixedFilters.activeView]
                        ).field,
                      fixedFilters.default,
                      fixedFilters.condition?.preset,
                    ),
                  }
                : actualFilter;
            });
          }
        }

        getToolbarConfigAndApplyFilters();
      });
  }

  _callAction(action) {
    /* Chrome Locked-Scroll Workaround */
    function scrollWorkaround() {
      const scrollArea = this.$.querySelector('#popup-add .div-section-crud');
      setTimeout(() => {
        scrollArea.style.overflow = 'auto';
      }, 500);
      setTimeout(() => {
        scrollArea.style.overflow = '';
      }, 600);
    }
    switch (action.actionType) {
      case 'add':
        this.stateConfig.toolbarConfig.addConfig.dataset = null;
        this.$.querySelector('#popup-add').toggle();
        scrollWorkaround.bind(this)();
        break;
      case 'share':
        this.$.querySelector('golfleet-popup-share').setData({
          type: this.stateConfig.type,
          getMethod: action.getDataMethod,
          shareMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({ objectId: row.id })),
        });
        this.$.querySelector('golfleet-popup-share').toggle();
        break;
      case 'edit':
        break;
      case 'delete':
        this.$.querySelector('golfleet-popup-delete').setData({
          deleteMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({
            id: row.id,
            description: this._getDescriptionField(row),
            value: row.id,
          })),
        });
        this.$.querySelector('golfleet-popup-delete').toggle();
        break;
      default:
        break;
    }
  }

  _getDescriptionField(row) {
    if (Array.isArray(this.stateConfig.gridConfig.descriptionField)) {
      const descriptionFields = this.stateConfig.gridConfig.descriptionField;

      const description = descriptionFields.reduce((acc, item, index) => {
        const gridHeader = this.stateConfig.gridConfig.gridHeaders.find(i => i.field === item);

        const value =
          gridHeader.type === 'DateTime'
            ? moment(new Date(row[item])).format('DD/MM/YYYY hh:mm:ss')
            : row[item];

        if (index === 0) {
          acc = value;
        } else {
          acc = `${acc} - ${value}`;
        }

        return acc;
      }, '');

      return description;
    }

    return row[this.stateConfig.gridConfig.descriptionField];
  }

  _goToLink(evt, evtParams) {
    let getDataFilters = [];
    const getDataFixedParams = {};

    if (evtParams.backPagination === null) {
      evtParams.backPagination = true;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const attr in evtParams.stateFixedParams) {
      if (evtParams.tableRowData[evtParams.stateFixedParams[attr]]) {
        getDataFixedParams[attr] = evtParams.tableRowData[evtParams.stateFixedParams[attr]];
      } else if (
        this.stateConfig.getDataFixedParams &&
        this.stateConfig.getDataFixedParams[evtParams.stateFixedParams[attr]]
      ) {
        getDataFixedParams[attr] =
          this.stateConfig.getDataFixedParams[evtParams.stateFixedParams[attr]];
      } else {
        getDataFixedParams[attr] = evtParams.stateFixedParams[attr];
      }
    }

    if (evtParams.fixedFilters) {
      getDataFilters = evtParams.fixedFilters.map(filter => {
        const filterDefault = filter.condition.map((condition, index) => {
          const data = evtParams.tableRowData[condition.field];
          if (filter.type == 'DateTime') {
            let conditionDate = filter.keepOffset ? moment(data) : moment(data).utcOffset(-3);

            conditionDate = conditionDate.subtract(condition.value, 'days');

            if (!filter.keepTime) {
              if (index == 0) conditionDate.hour(0).minute(0).second(0).millisecond(0);
              else if (index == 1) conditionDate.hour(23).minute(59).second(59).millisecond(0);
            }
            return conditionDate._d;
          }
          if (condition.value) {
            return data ? data + condition.value : condition.value;
          }

          return data;
        });

        return {
          id: filter.id,
          default: filterDefault,
          activeView: filter.activeView,
        };
      });
    }

    switch (evtParams.routeLink) {
      case 'report': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const stateConfig = new ReportStateConfig({
          viewMode: 'grid',
          getDataMethod: evtParams.getDataMethod,
          backPagination: evtParams.backPagination,
          getDataFilters,
          getDataFixedParams,
        });

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const attr in evtParams.linkStateConfig) {
          stateConfig[attr] = evtParams.linkStateConfig[attr];
        }

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: evtParams.tableRowData[evtParams.routeName] || evtParams.routeName,
            routeLink: evtParams.routeLink,
            routeTail: getDataFixedParams.id,
            stateConfig,
          },
        });
        this.$state.go(evtParams.routeLink, { tail: getDataFixedParams.id });

        break;
      }
      case 'streetView': {
        if (getDataFixedParams.paramsStreetView) {
          // 0. latitude, 1. longitude, 2. heading, 3. pitch, 4. zoom, 5. panoid
          const paramsStreetView = getDataFixedParams.paramsStreetView.split('|');
          window.open(
            'https://maps.google.com/maps?layer=c&q=' +
              `&cbll=${paramsStreetView[0]},${paramsStreetView[1]}` +
              `&cbp=11,${paramsStreetView[2]},0,` +
              `${paramsStreetView[4]},${paramsStreetView[3]}` +
              `&panoid=${paramsStreetView[5]}`,
            '_blank',
          );
        } else {
          window.open(
            'https://maps.google.com/maps?layer=c' +
              `&q=${getDataFixedParams.latitude},${getDataFixedParams.longitude}` +
              `&cbll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}` +
              '&cbp=11,0,0,0,0&z=17' +
              `&ll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}`,
            '_blank',
          );
        }

        break;
      }
      case 'drilldown': {
        const _granularity = ['mes', 'dia', 'hora'];
        const _momentMap = { mes: 'month', dia: 'day', hora: 'hour' };
        const navigationDateComponent = this.$.querySelector('golfleet-navigation-date');
        let datePath = navigationDateComponent.getPath();

        const lastIndex = datePath.length - 1;
        const [_startDate, _endDate] = getDataFixedParams.date;
        const { granularity: actualGranularity } = datePath[lastIndex];
        const dataEndDate = moment(_endDate).utcOffset(-3);
        const dataStartDate = moment(_startDate).utcOffset(-3);
        const { minPath, maxGranularity } = navigationDateComponent.getConfig();

        let granularity = '';
        let format = '';

        if (
          actualGranularity === _granularity[minPath] ||
          actualGranularity === _granularity[lastIndex]
        ) {
          switch (actualGranularity) {
            case 'mes':
              granularity = 'dia';
              format = 'MMMM [de] YYYY';
              break;
            case 'dia':
              granularity = 'hora';
              format = 'DD [de] MMMM';
              break;
            case 'hora':
              granularity = 'unitario';
              format = 'DD-MM-YYYY - HH:mm';
              break;
            default:
              break;
          }

          if (actualGranularity === maxGranularity) {
            granularity = 'unitario';
          }

          datePath = [
            ...datePath,
            {
              endDate: moment(dataEndDate).endOf(_momentMap[granularity])._d,
              startDate: moment(dataStartDate).startOf(_momentMap[granularity])._d,
              description: moment(dataStartDate).format(format),
              granularity,
            },
          ];
        } else {
          switch (lastIndex) {
            case 0:
              granularity = 'mes';
              break;
            case 1:
              granularity = 'dia';
              break;
            case 2:
              granularity = 'hora';
              break;
            case 3:
              granularity = 'unitario';
              break;
            default:
              granularity = 'sumarizado';
              break;
          }

          datePath[lastIndex].granularity = granularity;
        }

        navigationDateComponent.setup({
          path: datePath,
          minPath: this.stateConfig.navigation.dateMinPath,
        });
        this.stateConfig.navigation.date = datePath;
        this.stateConfig.gridConfig.page = 1;

        this.$scope.$broadcast('getDataset', {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: datePath[datePath.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          page: 1,
          ...this.stateConfig.getDataFixedParams,
        });

        break;
      }
      default: {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const stateConfig = new ReportStateConfig({
          viewMode: 'grid',
          getDataMethod: evtParams.getDataMethod,
          backPagination: evtParams.backPagination,
          getDataFilters,
          getDataFixedParams,
        });

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const attr in evtParams.linkStateConfig) {
          stateConfig[attr] = evtParams.linkStateConfig[attr];
        }

        const routeConfig = GetRouteConfig(evtParams.routeLink) || {};
        const metadata = routeConfig ? routeConfig.__metadata__ : {};

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: evtParams.tableRowData[evtParams.routeName] || evtParams.routeName,
            routeLink: evtParams.routeLink,
            routeTail: getDataFixedParams.id,
            stateConfig,
          },
          __metadata__: { ...metadata },
        });

        this.$state.go(evtParams.routeLink, { tail: getDataFixedParams.id });

        break;
      }
    }
  }

  // eslint-disable-next-line consistent-return
  _verifyColumnsVisibility() {
    if (this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders) {
      return this.stateConfig.gridConfig.gridHeaders.reduce((acc, header) => {
        if (header.fixed) return acc;
        if (!header.show) return false;
        return acc;
      }, true);
    }
  }

  _toggleAllColumnsVisibility() {
    if (this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders) {
      const showAll = !this._verifyColumnsVisibility();
      this.stateConfig.gridConfig.gridHeaders = this.stateConfig.gridConfig.gridHeaders.map(
        header => Object.assign(header, { show: header.fixed ? header.show : showAll }),
      );
      this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    }
  }

  _dayDiff(firstDate, secondDate) {
    return Math.abs(Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24)));
  }

  _convertCalendarPresetToDates(preset) {
    const getDateWithoutUTF = (date, modifier = 1) =>
      moment(date).utcOffset((modifier * moment(date).utcOffset()) / 60);

    let endDate = moment()
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(999)
      .subtract(preset.interval[1], preset.intervalType);
    let startDate = moment()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .subtract(preset.interval[0], preset.intervalType);

    switch (preset.intervalType) {
      case 'years':
        endDate =
          preset.interval[1] === 0 ? endDate : endDate.month(11).date(endDate.daysInMonth());
        startDate = startDate.month(0).date(1);
        break;
      case 'months':
        endDate = preset.interval[1] === 0 ? endDate : endDate.date(endDate.daysInMonth());
        startDate = startDate.date(1);
        break;
      default:
        break;
    }

    return [getDateWithoutUTF(startDate)._d, getDateWithoutUTF(endDate)._d];
  }

  _getFilterDataKey(filter) {
    // eslint-disable-next-line no-nested-ternary
    return typeof filter.field === 'string'
      ? filter.field
      : filter.filters
      ? `${filter.type}-${Object.keys(filter.filters).join('-')}`
      : `${filter.type}-${Object.values(filter.field).join('-')}`;
  }
  /* */

  /* Observers */
  __onRequestSyncVisualization(evt) {
    const cardElement = this.$.querySelector('#report-body-card');
    const gridElement = this.$.querySelector('#report-body-grid');

    if (cardElement && evt.target !== cardElement) {
      cardElement.scrollToIndex(evt.detail.index);
    }

    if (gridElement && evt.target !== gridElement) {
      gridElement.scrollToIndex(evt.detail.index);
    }
  }
  /* */
}

class GolfleetReport {
  constructor() {
    this.transclude = {
      addActionSlot: '?addActionSlot',
      footerActionSlot: '?footerActionSlot',
    };
    this.bindings = {
      actualView: '=?',
      hasMapView: '=?',
      hasAddAction: '=?',
      hasFooterAction: '=?',
    };
    this.controller = GolfleetReportController;
  }
}

angular.module('golfleet-report', ['dndLists']).component('golfleetReport', new GolfleetReport());

export { GolfleetReportController };
