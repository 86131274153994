import angular from 'angular';

import template from './golfleet-dashboard-error.html';

class GolfleetDashboardErrorController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
  }
}

class GolfleetDashboardError {
  constructor() {
    this.template = template;
    this.bindings = {
      icon: '=',
      title: '=',
      subtitle: '=',
    };
    this.controller = GolfleetDashboardErrorController;
  }
}

Object.assign(window, {
  GolfleetDashboardError,
  GolfleetDashboardErrorController,
});

angular
  .module('golfleet-dashboard-error', [])
  .component('golfleetDashboardError', new GolfleetDashboardError());
