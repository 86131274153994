import angular from 'angular';
import 'ng-redux';

import '../golfleet-filter-calendar/golfleet-filter-calendar';
import '../golfleet-filter-switch/golfleet-filter-switch';
import { FilterCondition, GolfleetFilterDataKey } from '../golfleet-filter/golfleet-filter';
import '../../helpers/object-clone/object-clone';

import template from './golfleet-filter-menu.html';
import './golfleet-filter-menu.scss';

class GolfleetFilterMenuController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux'];
  }

  constructor($element, $scope, $ngRedux) {
    Object.assign(this, { $: $element[0], $scope, $ngRedux });

    this.__golfleetBehavior = $ngRedux.connect(behavior =>
      Object({
        currentState: behavior.state.routeList[behavior.state.routeList.length - 1],
      }),
    )(this);

    this.watchFiltersInterval = null;
    this.watchFiltersValidateInterval = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      toggleFilter: this.toggleFilter.bind(this),
      applyFilters: this.applyFilters.bind(this),
    });

    this.$scope.$watch(
      () => JSON.stringify(this.currentState?.stateConfig?.filterConfig),
      this.__onFilterConfigStateChanged.bind(this),
    );
  }

  $onDestroy() {
    this.__golfleetBehavior();
  }
  /* */

  /* Public */
  toggle() {
    if (!this.$.hasAttribute('open')) this.$.setAttribute('open', '');
    else {
      this.$.removeAttribute('open');
      if (this.currentState.stateConfig.filterConfig)
        this.currentState.stateConfig.filterConfig.map(filter =>
          this.$.querySelector(`[data-id="${filter.id}"]`).removeAttribute('open'),
        );
    }
  }

  toggleFilter(filterId) {
    const filterNode = this.$.querySelector(`[data-id="${filterId}"]`);
    if (filterNode.hasAttribute('open')) filterNode.removeAttribute('open');
    else filterNode.setAttribute('open', '');
    this.$.querySelectorAll(`[open]:not([data-id="${filterId}"])`).forEach(node =>
      node.removeAttribute('open'),
    );
  }

  applyFilters() {
    const requestHandlerNode = this.$.parentElement.querySelector('#app-content > *');
    const dateControl = this.$.parentElement.querySelector('golfleet-date-control');
    if (!requestHandlerNode.requestDataset) return;
    if (this.watchFiltersInterval) clearInterval(this.watchFiltersInterval);
    this.watchFiltersInterval = setInterval(() => {
      if (
        this.currentState &&
        this.currentState.stateConfig &&
        this.currentState.stateConfig.filterConfig
      ) {
        const filterList = this.currentState.stateConfig.filterConfig.map(filter => ({
          ...filter,
        }));
        const readyFilterList = filterList.filter(filter => {
          const filterElement = this.$.querySelector(`[data-id="${filter.id}"]`);
          return !!filterElement && filterElement.loading !== undefined
            ? filterElement.loading === false && !!filter.condition
            : !!filter.condition;
        });
        if (readyFilterList.length == filterList.length) {
          this.currentState.stateConfig.filterConditions = Object.clone(
            [],
            readyFilterList.reduce((acc, filter) => {
              if (filter.type === 'datepicker') {
                acc.concat(this._computeDateTimeFilter(filter));
              } else if (filter.type === 'period') {
                if (filter.invalid) {
                  return acc;
                }

                if (Array.isArray(filter.condition.value) && Array.isArray(filter.default)) {
                  const isDefaultValue = filter.condition.value.every((value, index) => {
                    const defaultValue = filter.default[index];
                    return value === Number(defaultValue || 0);
                  });

                  if (isDefaultValue) {
                    return acc;
                  }
                }
              } else if (filter.advanced && Array.isArray(filter.condition)) {
                filter.condition.forEach(condition => acc.push(condition));
                return acc;
              }

              return acc.concat(filter.condition);
            }, []),
          );
          if (this.$.hasAttribute('open')) this.toggle();
          this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
          if (dateControl) {
            const [dateCondition] = filterList.filter(filter => filter.type === 'calendar');
            dateControl.updateDateValue(dateCondition);
          } else {
            requestHandlerNode.requestDataset();
          }
          clearInterval(this.watchFiltersInterval);
        }
      }
    }, 250);
  }

  /* */

  /* Private */
  _computeDateTimeFilter(filter) {
    const initDate = new Date(filter.condition.value[0]);
    const finDate = new Date(filter.condition.value[1]);
    return new FilterCondition(filter.condition.id, filter.condition.field, [
      new Date(initDate.setHours(initDate.getHours() - initDate.getTimezoneOffset() / 60)),
      new Date(finDate.setHours(finDate.getHours() - finDate.getTimezoneOffset() / 60)),
    ]);
  }

  _filterAddWarningButton({ filterKey, warningTitle, warningButtonClickEvent }) {
    const filterHeaderElement = document.querySelector(
      `golfleet-filter-menu [data-key=${filterKey}] .filter-header`,
    );

    if (!document.querySelector(`[data-key=warning-${filterKey}]`)) {
      /* Warning Filter Button */
      const warningFilterButton = document.createElement('div');
      warningFilterButton.setAttribute('data-key', `warning-${filterKey}`);
      warningFilterButton.setAttribute('role', 'button');
      warningFilterButton.setAttribute('class', 'warning-button');

      if (warningButtonClickEvent) {
        warningFilterButton.addEventListener('click', warningButtonClickEvent);
      } else {
        warningFilterButton.style.pointerEvents = 'none';
      }

      const warningFilterButtonIcon = document.createElement('i');
      warningFilterButtonIcon.setAttribute('class', 'material-icons ng-binding');
      warningFilterButtonIcon.innerHTML = 'warning';

      const warningFilterButtonText = document.createElement('span');
      warningFilterButtonText.innerHTML = warningTitle;

      warningFilterButton.append(warningFilterButtonIcon);
      warningFilterButton.append(warningFilterButtonText);

      if (filterHeaderElement) {
        filterHeaderElement.style.backgroundImage = 'url(assets/images/menu-warning.svg)';
        filterHeaderElement.style.backgroundRepeat = 'no-repeat';
        filterHeaderElement.style.backgroundPositionX = '6px';
        filterHeaderElement.style.backgroundPositionY = '10px';
        filterHeaderElement.style.backgroundSize = '16px';

        filterHeaderElement.setAttribute('warning', '');
        filterHeaderElement.parentElement.append(warningFilterButton);
      }

      /* Filter Preview */
      const warningFilterPreview = document.createElement('img');
      warningFilterPreview.setAttribute('src', 'assets/images/menu-warning.svg');
      warningFilterPreview.setAttribute('class', 'warning');
      warningFilterPreview.innerHTML = 'warning';

      const filterPreview = document.querySelector(
        `[data-key=${filterKey}].filter-preview-content-item`,
      );
      if (filterPreview) {
        filterPreview.append(warningFilterPreview);
        filterPreview.setAttribute('warning', '');
      }
    }
  }

  _filterRemoveWarningButton({ filterKey }) {
    const warningFilterButton = document.querySelector(`[data-key=warning-${filterKey}]`);
    const filterPreviewWarning = document.querySelector(
      `[data-key=${filterKey}].filter-preview-content-item img`,
    );

    if (warningFilterButton) {
      const parentelement = warningFilterButton.parentElement;
      parentelement.removeChild(warningFilterButton);

      const filterHeaderElement = parentelement.querySelector('.filter-header');
      filterHeaderElement.style.backgroundImage = null;
      filterHeaderElement.style.backgroundRepeat = null;
      filterHeaderElement.style.backgroundPositionX = null;
      filterHeaderElement.style.backgroundPositionY = null;
      filterHeaderElement.style.backgroundSize = null;
      filterHeaderElement.removeAttribute('warning');
    }

    if (filterPreviewWarning) {
      const parentelement = filterPreviewWarning.parentElement;
      parentelement.removeChild(filterPreviewWarning);
      parentelement.removeAttribute('warning', '');
    }
  }

  _validateConditionalFilters() {
    if (this.currentState.stateConfig.filterConfig) {
      this.currentState.stateConfig.filterConfig.forEach(filter => {
        if (filter.conditional) {
          const filterCalendar = this.$.querySelector('golfleet-filter-calendar');
          const selectedDaysCount = filterCalendar.getNumberOfDays();

          if (selectedDaysCount > 7) {
            filter.invalid = true;
            this._filterAddWarningButton({
              warningTitle: 'Para utilizar este filtro, selecione no máximo 7 dias',
              filterKey: this._getFilterDataKey(filter),
            });
          } else {
            filter.invalid = false;
            this._filterRemoveWarningButton({ filterKey: this._getFilterDataKey(filter) });
          }
        }
      });
    }
  }

  _getFilterDataKey(filter) {
    return GolfleetFilterDataKey(filter);
  }
  /* */

  /* Observers */
  __onFilterConfigStateChanged() {
    const filterTitleElement = this.$.querySelector('#filter-menu-header > span');
    const filterToggleElement = document.querySelector('#navigation-filter-toggle');
    const filterCounterElement = filterToggleElement?.querySelector('span.filter-counter');

    if (filterToggleElement && filterCounterElement) {
      let countSelectedFilters = 0;
      const filterDataIds = [];

      this.currentState?.stateConfig?.filterConfig?.forEach(filter => {
        if (filter.visible === false) return;

        if (Array.isArray(filter.condition)) {
          countSelectedFilters += filter.condition.reduce((acc, condition) => {
            if (condition.value?.some(value => value === 0 || !!value)) {
              filterDataIds.push(filter.id);
              return ++acc;
            }
            return acc;
          }, 0);
        } else if (
          filter.condition?.value?.some(value => value === 0 || !!value) ||
          filter.condition?.options?.some(opt => opt.selected)
        ) {
          filterDataIds.push(filter.id);
          countSelectedFilters++;
        }
      });

      this.$.querySelectorAll('#filter-menu-content [active]').forEach(el => {
        el.removeAttribute('active');
      });

      if (countSelectedFilters > 0) {
        filterToggleElement.setAttribute('show-counter', '');
        filterCounterElement.innerHTML = `${countSelectedFilters}`.padStart(2, '0');

        filterTitleElement.innerHTML =
          countSelectedFilters > 1 ? `${countSelectedFilters} Filtros ativos` : '1 Filtro ativo';

        filterDataIds.forEach(id => {
          const _setElementActive = () => {
            const element = this.$.querySelector(`#filter-menu-content [data-id="${id}"]`);
            if (element) {
              element.setAttribute('active', '');
              return true;
            }
            return false;
          };

          if (!_setElementActive()) {
            const interval = setInterval(() => {
              if (_setElementActive()) {
                clearInterval(interval);
              }
            }, 100);
          }
        });
      } else {
        filterToggleElement.removeAttribute('show-counter');
        filterTitleElement.innerHTML = 'Filtros';
      }
    }
  }
  /* */
}

class GolfleetFilterMenu {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFilterMenuController;
  }
}

angular
  .module('golfleet-filter-menu', ['ngRedux', 'golfleet-filter-calendar', 'golfleet-filter-switch'])
  .component('golfleetFilterMenu', new GolfleetFilterMenu());
